import Vue from 'vue';
import * as NotificationStore from '@/store/modules/NotificationStore';
export default Vue.extend({
    name: 'notification',
    components: {
        contentRender: {
            props: ['component'],
            render: function (createElement) {
                return createElement(this.component);
            }
        }
    },
    props: {
        message: String,
        title: {
            type: String
        },
        icon: {
            type: String
        },
        verticalAlign: {
            type: String,
            default: 'top',
            validator: (value) => {
                let acceptedValues = ['top', 'bottom'];
                return acceptedValues.indexOf(value) !== -1;
            }
        },
        horizontalAlign: {
            type: String,
            default: 'center',
            validator: (value) => {
                let acceptedValues = ['left', 'center', 'right'];
                return acceptedValues.indexOf(value) !== -1;
            }
        },
        type: {
            type: String,
            default: 'info',
            validator: (value) => {
                let acceptedValues = ['default', 'info', 'primary', 'danger', 'warning', 'success'];
                return acceptedValues.indexOf(value) !== -1;
            }
        },
        timeout: {
            type: Number,
            default: 5000,
            validator: (value) => {
                return value >= 0;
            }
        },
        timestamp: {
            type: Number,
            default: () => new Date().getTime()
        },
        component: {
            type: [Object, Function]
        },
        showClose: {
            type: Boolean,
            default: true
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        clickHandler: {
            type: Function
        }
    },
    data() {
        return {
            elmHeight: 0
        };
    },
    computed: {
        hasIcon() {
            return this.icon && this.icon.length > 0;
        },
        alertType() {
            return `alert-${this.type}`;
        },
        notifications() {
            return NotificationStore.getters.getNotifications;
        },
        customPosition() {
            let initialMargin = 20;
            let alertHeight = this.elmHeight + 10;
            let sameAlertsCount = this.notifications.filter((alert) => {
                return (alert.horizontalAlign === this.horizontalAlign &&
                    alert.verticalAlign === this.verticalAlign &&
                    alert.timestamp <= this.timestamp);
            }).length;
            let pixels = (sameAlertsCount - 1) * alertHeight + initialMargin;
            let styles = {
                top: '',
                bottom: ''
            };
            if (this.verticalAlign === 'top') {
                styles.top = `${pixels}px`;
            }
            else {
                styles.bottom = `${pixels}px`;
            }
            return styles;
        }
    },
    methods: {
        close() {
            this.$emit('close', this.timestamp);
        },
        tryClose(evt) {
            if (this.clickHandler) {
                this.clickHandler(evt, this);
            }
            if (this.closeOnClick) {
                this.close();
            }
        }
    },
    mounted() {
        this.elmHeight = this.$el.clientHeight;
        if (this.timeout) {
            setTimeout(this.close, this.timeout);
        }
    }
});

import { mapGetters } from 'vuex';
import Client from '@/service/Client';
// @logan yes this is actually used for referral info for sharing the app.
export default {
    name: 'ReferralRedirect',
    components: {},
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            user: 'Session/getUser'
        })
    },
    async mounted() {
        // console.log('params', this.$route.params.id)
        try {
            await Client.referralAnalytics(this.$route.params.id);
        }
        catch (err) {
            console.log(err);
        }
        window.location.href = 'https://www.apple.com/app-store/';
    },
    methods: {}
};

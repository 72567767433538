import { defineComponent } from "vue";
// modals
// import CampaignModal from './modals/CampaignModal'
import BasicModal from "./modals/BasicModal.vue";
import ConfirmationModal from "./modals/ConfirmationModal.vue";
import ChangelogModal from "@/modals/ChangelogModal.vue";
import GiftCardModal from "@/modals/GiftCardModal.vue";
import OffersModal from "@/offers/components/OffersModal.vue";
// vuex state
import * as authStore from "@/store/modules/AuthStore";
export default defineComponent({
    name: "App",
    components: {
        BasicModal,
        ConfirmationModal,
        ChangelogModal,
        GiftCardModal,
        OffersModal,
    },
    data() {
        return {
            initialLoaded: false,
            loading: false,
            basicModal: {
                show: false,
                opts: {
                    title: "",
                    text: "",
                    mode: "ok",
                    confirmText: "Ok",
                },
            },
            campaignModal: {
                show: false,
                opts: {
                    create: true,
                    createType: "campaign",
                },
            },
            confirmationModal: {
                show: false,
                data: {
                    title: "",
                    icon: "",
                    iconVariant: "",
                    headerText: "",
                    bodyText: "",
                    confirmText: "",
                    cancelText: "",
                    confirmAction: () => null,
                    cancelAction: () => null,
                },
            },
        };
    },
    computed: {},
    async created() {
        // console.log('App Initialized.')
        // Set up global error handler.
        this.$events.$on("error", (err) => {
            console.log("LOGGING DAT ERROR TOPSIDE;", err);
            const isWeb = err.response;
            if (isWeb) {
                let showErr = err.response.data.error;
                if (!showErr) {
                    showErr = "Sorry, something went wrong. Please try again.";
                }
                if (err.response.status === 401) {
                    this.$events.$emit("showBasicModal", {
                        title: "Error",
                        text: showErr,
                        mode: "ok",
                    });
                    // this.$session
                    //     .logOut()
                    //     .then(() => {
                    //         this.$router.push('/login')
                    //     })
                    //     .catch((subErr) => {
                    //         this.$router.push('/login')
                    //     })
                }
                else {
                    this.$events.$emit("showBasicModal", {
                        title: "Error",
                        text: showErr,
                        mode: "ok",
                    });
                }
            }
            else {
                if (err.status === 401) {
                    this.$session
                        .logOut()
                        .then(() => {
                        this.$router.push("/login");
                    })
                        .catch((subErr) => {
                        this.$router.push("/login");
                    });
                }
                else {
                    if (!err.message)
                        err.message =
                            "Sorry, something went wrong. Please try again.";
                    this.$events.$emit("showBasicModal", {
                        title: "Error",
                        text: err.message,
                        mode: "ok",
                    });
                }
            }
        });
        // Set up modals and loading overlay
        this.$events.$on("showLoading", () => {
            this.loading = true;
        });
        this.$events.$on("hideLoading", () => {
            this.loading = false;
        });
        this.$events.$on("showBasicModal", (opts) => {
            this.basicModal.opts = opts;
            if (!this.basicModal.opts.confirmText)
                this.basicModal.opts.confirmText = "OK";
            if (!this.basicModal.opts.cancelText)
                this.basicModal.opts.cancelText = "Cancel";
            this.basicModal.show = true;
        });
        this.$events.$on("showConfirmationModal", (data) => {
            this.confirmationModal.data = data;
            this.confirmationModal.show = true;
        });
        this.$events.$on("showCampaignModal", (opts) => {
            this.campaignModal.opts = opts;
            this.campaignModal.show = true;
            window.scrollTo(0, 0);
        });
        this.$events.$on("closeCampaignModal", () => {
            this.closeCampaignModal();
        });
        // Handle page changes
        // window.beforeunload = this.leaving
        // window.onblur = this.leaving
        this.initialLoaded = true;
    },
    async mounted() {
        await authStore.actions.authAction(this.$router);
    },
    methods: {
        closeBasicModal() {
            this.basicModal.show = false;
        },
        closeCampaignModal() {
            this.campaignModal.show = false;
        },
        closeConfirmationModal() {
            this.confirmationModal.show = false;
        },
    },
});
// export default Vue.extend()

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/parallax4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".content-image[data-v-30aef43d] {\n  height: 900px;\n}\n.bg-image[data-v-30aef43d] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n}\n.min-vh-100[data-v-30aef43d] {\n  min-height: 100vh;\n}", "",{"version":3,"sources":["webpack://./src/pages/landing/CreateMerchantAccount.vue"],"names":[],"mappings":"AACA;EACI,aAAA;AAAJ;AAGA;EACI,yDAAA;EACA,sBAAA;AAAJ;AAIA;EACI,iBAAA;AADJ","sourcesContent":["\n.content-image {\n    height: 900px;\n}\n\n.bg-image {\n    background-image: url('@/assets/img/parallax4.jpg');\n    background-size: cover;\n}\n\n\n.min-vh-100 {\n    min-height: 100vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

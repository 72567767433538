import Axios from "axios";
import { REQUEST_METHOD, } from "@gd-mono/gd-common";
import Client, { API_URL } from "./Client";
export class TransactionClient {
    async readAll() {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/transactions`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    async readByUserId(targetUserId, callingUser) {
        const authToken = await Client.getAuthToken();
        const resp = await Axios({
            method: REQUEST_METHOD.GET,
            url: `${API_URL}/transactions?userId=${targetUserId}`,
            headers: {
                authToken,
            },
        });
        return resp.data.data;
    }
    create(transaction, callingUser) {
        throw new Error("Method not implemented.");
    }
    readTransactionByPaymentIntentId(paymentIntentId) {
        throw new Error("Method not implemented.");
    }
    update(transaction, callingUser) {
        throw new Error("Method not implemented.");
    }
    updateWebhookStatus(transaction) {
        throw new Error("Method not implemented.");
    }
    read(id, callingUser) {
        throw new Error("Method not implemented.");
    }
}

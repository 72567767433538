var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-badge",
    {
      class: {
        "badge-circle": _vm.circle,
      },
      attrs: {
        variant: _vm.type,
        pill: _vm.rounded,
        size: _vm.size,
      },
    },
    [
      _vm._t("default", function () {
        return [
          _vm.icon
            ? _c("i", {
                class: _vm.icon,
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import Vue from "vue";
import OffersCardComponent from "./OffersCardComponent.vue";
import * as offerStore from "@/store/modules/OfferStore";
export default Vue.extend({
    props: {
        selectedSource: {
            type: String,
        },
    },
    components: { OffersCardComponent },
    methods: {
        selectionChange(val) {
            this.$emit("offerSourceChange", val);
            if (val.source === "INVENTORY") {
                offerStore.mutations.setTargetOfferInventorySubType("GIFTCARD_INVENTORY");
            }
        },
        alert(message) {
            alert(message);
        },
    },
});

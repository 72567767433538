//This page probably needs a complete makeover
import Vue from 'vue';
import * as gratitudeStore from '@/store/modules/GratitudeStore';
import BaseHeader from '@/argon/components/BaseHeader.vue';
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue';
import Client from '@/service/Client';
import * as notificationStore from '@/store/modules/NotificationStore';
export default Vue.extend({
    name: 'GratitudeManager',
    components: { BaseHeader, RouteBreadCrumb },
    data() {
        return {
            newGratImageUrl: null,
            newGratText: null,
            imageValid: true,
            isHovered: false
        };
    },
    computed: {
        activeImages() {
            return gratitudeStore.getters.gratitudeImages;
        },
        inactiveImages() {
            return gratitudeStore.getters.gratitudeImagesInactive;
        },
        activeText() {
            return gratitudeStore.getters.gratitudeText;
        },
        inactiveText() {
            return gratitudeStore.getters.gratitudeTextInactive;
        }
    },
    async mounted() {
        await gratitudeStore.actions.getAllGratitudes();
    },
    methods: {
        handleHover(hovered) {
            this.isHovered = hovered;
        },
        archive(gratitude) {
            this.$events.$emit('showConfirmationModal', {
                title: 'Archive Thank You',
                icon: 'exclamation-triangle',
                iconVariant: 'warning',
                headerText: 'Are you sure you want to archive this Thank You?',
                bodyText: '',
                confirmText: 'Confirm',
                cancelText: 'Cancel',
                confirmAction: async () => {
                    this.$events.$emit('showLoading');
                    try {
                        let newGrat = Object.assign({}, gratitude);
                        newGrat.active = !newGrat.active;
                        await gratitudeStore.actions.updateGratitude(newGrat);
                        await gratitudeStore.actions.getAllGratitudes();
                        this.$events.$emit('hideLoading');
                    }
                    catch (err) {
                        this.$events.$emit('hideLoading');
                        this.$events.$emit('error', err);
                    }
                }
            });
        },
        restore(gratitude) {
            this.$events.$emit('showConfirmationModal', {
                title: 'Restore Thank You',
                icon: 'exclamation-triangle',
                iconVariant: 'warning',
                headerText: 'Are you sure you want to restore this Thank You?',
                bodyText: '',
                confirmText: 'Confirm',
                cancelText: 'Cancel',
                confirmAction: async () => {
                    this.$events.$emit('showLoading');
                    try {
                        let newGrat = Object.assign({}, gratitude);
                        newGrat.active = !newGrat.active;
                        await gratitudeStore.actions.updateGratitude(newGrat);
                        await gratitudeStore.actions.getAllGratitudes();
                        this.$events.$emit('hideLoading');
                    }
                    catch (err) {
                        this.$events.$emit('hideLoading');
                        this.$events.$emit('error', err);
                    }
                }
            });
        },
        remove(gratId) {
            this.$events.$emit('showConfirmationModal', {
                title: 'Remove Thank You',
                icon: 'exclamation-triangle',
                iconVariant: 'warning',
                headerText: 'Are you sure you want to remove this Thank You?',
                bodyText: "This action can't be undone",
                confirmText: 'Confirm',
                cancelText: 'Cancel',
                confirmAction: async () => {
                    this.$events.$emit('showLoading');
                    try {
                        await gratitudeStore.actions.removeGratitude(gratId);
                        await gratitudeStore.actions.getAllGratitudes();
                        this.$events.$emit('hideLoading');
                    }
                    catch (err) {
                        this.$events.$emit('hideLoading');
                        this.$events.$emit('error', err);
                    }
                }
            });
        },
        async createNewGratitude(gratType, gratVal) {
            const data = {
                data: gratVal,
                type: gratType,
                id: null,
                active: true,
            };
            const payload = {
                data
            };
            this.$events.$emit('showLoading');
            try {
                await gratitudeStore.actions.createGratitude(payload.data);
                await gratitudeStore.actions.getAllGratitudes();
                this.newGratText = null;
                this.removeAvatarImage();
                this.$events.$emit('hideLoading');
            }
            catch (err) {
                this.$events.$emit('hideLoading');
                this.$events.$emit('error', err);
            }
        },
        uploadFile: async function (evt) {
            if (evt.target.files.length < 1) {
                return;
            }
            this.$events.$emit('showLoading');
            let file = evt.target.files;
            try {
                let image = new Image();
                image.onload = async () => {
                    var height = image.width;
                    var width = image.height;
                    if (width === height) {
                        this.imageValid = true;
                        const resp = await Client.uploadImage(file);
                        this.newGratImageUrl = resp.data.secure_url;
                        notificationStore.actions.pushSuccess({
                            title: 'Thank you image uploaded.'
                        });
                        this.$events.$emit('hideLoading');
                        return;
                    }
                    else {
                        alert('Image width and height not equal.');
                        this.imageValid = false;
                        this.$events.$emit('hideLoading');
                    }
                };
                image.src = window.URL.createObjectURL(file[0]);
            }
            catch (err) {
                this.$events.$emit('hideLoading');
                this.$events.$emit('error', err);
            }
        },
        removeAvatarImage() {
            this.$refs.file.type = 'file';
            this.newGratImageUrl = null;
        }
    }
});

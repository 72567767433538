import Vue from 'vue';
import Modal from '@/argon/components/Modal.vue';
export default Vue.extend({
    name: 'ConfirmationModal',
    components: {
        Modal
    },
    props: {
        title: {
            type: String,
            default: 'Confirm Action'
        },
        icon: {
            type: String,
            default: 'check2-circle'
        },
        iconVariant: {
            type: String,
            default: 'primary'
        },
        headerText: {
            type: String,
            default: 'Please Confirm'
        },
        bodyText: {
            type: String,
            default: ''
        },
        confirmText: {
            type: String,
            default: 'Ok'
        },
        confirmAction: {
            type: Function
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('cancelAction');
        },
        emitConfirmAction() {
            this.close();
            this.$emit('confirmAction');
        },
        emitCancelAction() {
            this.close();
            this.$emit('cancelAction');
        }
    }
});

export default {
    name: 'LandingHeader',
    components: {},
    data() {
        return {
            showMenu: false,
            scrolled: false
        };
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.scrolled = window.scrollY > 0;
            // console.log("*** ***", this.scrolled);
        }
    }
};

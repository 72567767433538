import Vue from 'vue';
import pdf from 'vue-pdf';
import * as offerStore from '@/store/modules/OfferStore';
export default Vue.extend({
    name: 'MediaContainer',
    props: {
        onRemove: {
            type: Function,
            required: true
        },
        src: {
            type: String,
            required: true
        },
        pdf: {
            type: Boolean,
            default: false
        },
        video: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {
        pdf
    },
    data() {
        return {
            pdfSource: null,
            hover: false
        };
    },
    async mounted() {
        if (this.pdf) {
            await this.getPDFSource();
        }
    },
    computed: {},
    methods: {
        async getPDFSource() {
            this.pdfSource = await offerStore.actions.getURLAsPDF(this.src);
        }
    }
});

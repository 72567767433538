import firebase from "firebase/app";
import "firebase/auth";
import { storeBuilder } from "@/store/RootState";
import * as notificationStore from "./NotificationStore";
import * as companyStore from "./CompanyStore";
import { USER_ROLES } from "@gd-mono/gd-common";
import { UserClientImpl } from "@/service/UserClientImpl";
/**
 * @description Base store state for handling auth data, handles firebase auth observer and getting a user
 * @author Logan Hendershot
 * @date 10/14/2021
 * @export
 * @class AuthState
 */
export class AuthState {
    loading = false;
    creatingUser = false;
    user = null;
}
// auth module builder
const authModule = storeBuilder.module("auth", new AuthState());
// getters
const getterFunctions = {
    getUser: authModule.read((state) => state.user, "getUser"),
    isAdmin: authModule.read((state) => state.user?.role === USER_ROLES.ADMIN, "isAdmin"),
    isMerchant: authModule.read((state) => state.user?.role === USER_ROLES.MERCHANT, "isMerchant"),
    isValid: authModule.read((state) => state.user?.approved && state.user?.emailVerified, "isValid"),
    getPhone: authModule.read((state) => state.user.phone, "getPhone"),
    getLoading: authModule.read((state) => state.loading, "getLoading"),
};
export const getters = {
    get getUser() {
        return getterFunctions.getUser();
    },
    get isAdmin() {
        return getterFunctions.isAdmin();
    },
    get isMerchant() {
        return getterFunctions.isMerchant();
    },
    get isValid() {
        return getterFunctions.isValid();
    },
    get getPhone() {
        return getterFunctions.getPhone();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
};
// mutations
function setUser(state, user) {
    state.user = user;
}
function setFirst(state, payload) {
    state.user.first = payload;
}
function setLast(state, payload) {
    state.user.last = payload;
}
function setPhone(state, payload) {
    state.user.phone = payload;
}
function setEmail(state, payload) {
    state.user.email = payload;
}
function setPin(state, payload) {
    state.user.pin = payload;
}
function setLoading(state, payload) {
    state.loading = payload;
}
function setCreatingUser(state, payload) {
    state.loading = payload;
}
export const mutations = {
    setUser: authModule.commit(setUser),
    setFirst: authModule.commit(setFirst),
    setLast: authModule.commit(setLast),
    setEmail: authModule.commit(setEmail),
    setPhone: authModule.commit(setPhone),
    setPin: authModule.commit(setPin),
    setLoading: authModule.commit(setLoading),
    setCreatingUser: authModule.commit(setCreatingUser),
};
/**
 * can include context: ActionContext as the first argument if you need root state
 *
 * @param {ActionContext} context
 * @param {{ emailAddress: string; password: string }} payload
 */
async function loginAction(context, payload) {
    const { emailAddress, password } = payload;
    try {
        await firebase.auth().signInWithEmailAndPassword(emailAddress, password);
        const userId = firebase.auth().currentUser.uid;
        const user = await UserClientImpl.getInstance().read(userId);
        mutations.setUser(user);
    }
    catch (error) {
        // console.log("err", error)
        notificationStore.actions.pushError({
            title: "Unable to Login.",
            error,
        });
        if (error.code === "auth/wrong-password" ||
            error.code === "auth/user-not-found") {
            error.invalidCredentials = true;
        }
        throw error;
    }
}
/**
 * can include context: ActionContext as the first argument if you need root state
 *
 * @param {ActionContext} context
 * @param {{ emailAddress: string; password: string }} payload
 */
async function createUserAction(context, payload) {
    const { email, password } = payload;
    try {
        mutations.setCreatingUser(true);
        await firebase.auth().createUserWithEmailAndPassword(email, password);
        await firebase.auth().currentUser.sendEmailVerification();
        await firebase.auth().currentUser.updateProfile({
            displayName: `${payload.first} ${payload.last}`,
        });
        // remove password once created
        payload.password = undefined;
        const user = await UserClientImpl.getInstance().create(payload);
        mutations.setUser(user);
        mutations.setCreatingUser(false);
    }
    catch (error) {
        console.log("err", error);
        notificationStore.actions.pushError({
            title: "Unable to Create User.",
            error,
        });
        mutations.setCreatingUser(false);
        throw error;
    }
}
async function logoutAction() {
    try {
        await firebase.auth().signOut();
        mutations.setUser(null);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error logging out.",
            error,
        });
    }
}
async function authAction(context, router) {
    firebase.auth().onIdTokenChanged(async (firebaseUser) => {
        if (firebaseUser && !context.state.creatingUser) {
            console.log("Login Change.", firebaseUser.uid);
            console.log("token - ", await firebaseUser.getIdToken());
            try {
                const user = await UserClientImpl.getInstance().read(firebaseUser.uid);
                console.log("User - ", user);
                mutations.setUser(user);
                // console.log(await firebaseUser.getIdToken())
                await companyStore.actions.getAuthedUserCompanyAction();
            }
            catch (error) {
                console.log("err", error);
                notificationStore.actions.pushError({
                    title: "Auth action error.",
                    error,
                });
            }
            companyStore.mutations.setAuthedUserCompany(companyStore.getters.getCompany);
        }
        else {
            mutations.setUser(null);
        }
    });
}
async function getUserAction(context) {
    try {
        const user = await UserClientImpl.getInstance().read(context.state.user.id);
        mutations.setUser(user);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error getting user.",
            error,
        });
    }
}
async function updateUserAction(context) {
    try {
        const user = await UserClientImpl.getInstance().update(context.state.user);
        mutations.setUser(user);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error updating user.",
            error,
        });
    }
}
export const actions = {
    loginAction: authModule.dispatch(loginAction),
    createUserAction: authModule.dispatch(createUserAction),
    logoutAction: authModule.dispatch(logoutAction),
    authAction: authModule.dispatch(authAction),
    getUserAction: authModule.dispatch(getUserAction),
    updateUserAction: authModule.dispatch(updateUserAction),
};

import Vue from 'vue';
import { gmapApi } from 'vue2-google-maps';
import * as companyStore from '@/store/modules/CompanyStore';
import * as appStore from '@/store/modules/AppStore';
import { CompanyLocation } from '@gd-mono/gd-common';
export default Vue.extend({
    name: 'CompanyLocations',
    data() {
        return {
            mapCenter: {
                position: {
                    lat: 45.5017,
                    lng: -122.5673
                },
                zoom: 11
            },
            addLocations: false,
            value: null
        };
    },
    mounted() {
        this.addLocations = this.company?.locations.length > 0;
        // this.mapCenter.position = appStore.getters.getPosition
    },
    computed: {
        position() {
            return appStore.getters.getPosition;
        },
        company() {
            return companyStore.getters.getCompany;
        },
        editing() {
            return companyStore.getters.isEditing;
        },
        creating() {
            return companyStore.getters.isCreating;
        },
        viewing() {
            return companyStore.getters.isViewing;
        },
        loading() {
            return companyStore.getters.getLoading;
        },
        hasLocations() {
            return companyStore.getters.getCompany?.locations;
        },
        google: gmapApi
    },
    watch: {
        company() {
            this.addLocations = companyStore.getters.getCompany?.locations.length > 0;
        }
    },
    methods: {
        // used to toggle the adding of locations
        locationSwitch(val) {
            this.addLocations = val;
        },
        // takes in a google place object and formats it for saving as CompanyLocation
        setPlace(place) {
            // if no place provided, return
            if (!place)
                return;
            // set map to center on selected place
            this.mapCenter.position = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            // create a formatted place, getting info from given place
            let formattedPlace = new CompanyLocation();
            formattedPlace.address = place.formatted_address;
            formattedPlace.location = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };
            formattedPlace.placeId = place.place_id;
            formattedPlace.name = place.name;
            // add location into company
            companyStore.mutations.addLocations(formattedPlace);
            // reset the autocomplete field i think
            this.$refs.autoComplete.value = this.value;
        },
        // drops the location by splicing
        dropLocation(index) {
            companyStore.mutations.spliceLocations(index);
        }
    }
});

import Vue from 'vue';
export default Vue.extend({
    data() {
        return {};
    },
    components: {},
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    computed: {},
    methods: {}
});

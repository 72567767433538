import { Utils } from '@gd-mono/gd-common';
import Vue from 'vue';
export default Vue.extend({
    components: {},
    data() {
        return {};
    },
    props: {
        targetOffer: {
            type: Object,
            required: true
        }
    },
    computed: {
        subType() {
            return this.targetOffer?.sub_type;
        },
        inventorySubType() {
            return this.targetOffer?.inventory_sub_type;
        },
        utils() {
            return Utils;
        }
    }
});

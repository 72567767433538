import Vue from 'vue';
import * as companyStore from '@/store/modules/CompanyStore';
import * as authStore from '@/store/modules/AuthStore';
import { USER_ROLES } from '@gd-mono/gd-common';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default Vue.extend({
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu
    },
    data() {
        return {
            searchTerms: '',
            companies: []
        };
    },
    async mounted() {
        await companyStore.actions.getAllCompanies();
        this.companies = this.allCompanies;
    },
    computed: {
        allCompanies() {
            return companyStore.getters.getAllCompanies;
        },
        loading() {
            return companyStore.getters.getLoading;
        },
        user() {
            return authStore.getters.getUser;
        },
        authUserHasCompany() {
            return !!this.user.companyId;
        },
        authUserIsAdmin() {
            return this.user.role === USER_ROLES.ADMIN;
        }
    },
    watch: {
        searchTerms(terms) {
            if (!terms)
                return (this.companies = this.allCompanies);
            this.companies = this.allCompanies.filter((company) => {
                return company.name.toLowerCase().search(terms.toLowerCase()) !== -1;
            });
        }
    },
    methods: {
        async handleCommand(commandObject) {
            if (!commandObject) {
                return;
            }
            switch (commandObject.action) {
                case 'request-membership':
                    await companyStore.actions.requestToJoin(commandObject.campaign.id);
                    break;
                case 'select':
                    await this.$emit('select', commandObject.campaign);
                    break;
                default:
                // no op
            }
        }
    }
});

import { defineComponent } from "vue";
import { ValidationObserver } from "vee-validate";
import OffersTypeSelect from "./OffersTypeSelect.vue";
import * as offerStore from "@/store/modules/OfferStore";
import { VIEW_STATE } from "@/store/RootState";
export default defineComponent({
    name: "OffersModal",
    components: {
        ValidationObserver,
        OffersTypeSelect,
    },
    computed: {
        loading() {
            return offerStore.getters.loading;
        },
        editing() {
            return offerStore.getters.viewState === VIEW_STATE.EDITING;
        },
        viewing() {
            return offerStore.getters.viewState === VIEW_STATE.VIEWING;
        },
        offerStore() {
            return offerStore;
        },
        targetOffer() {
            return offerStore.getters.targetOffer;
        },
        viewState() {
            return offerStore.getters.viewState;
        },
        targetProvider() {
            return offerStore.getters.targetProvider;
        },
    },
    watch: {
        // validated on targetOffer change
        async targetOffer() {
            await this.$refs.observer.validate();
        },
        async viewState() {
            await this.$refs.observer.validate();
        },
    },
    methods: {
        async handleSave() {
            if (this.editing) {
                await offerStore.actions.updateOffer();
                this.$bvModal.hide("offers-modal");
                offerStore.mutations.clearViewState();
                offerStore.mutations.clearTargetOffer();
                return;
            }
            await offerStore.actions.createOffer();
            this.$bvModal.hide("offers-modal");
            offerStore.mutations.clearViewState();
            offerStore.mutations.clearTargetOffer();
            return;
        },
        onClose() {
            offerStore.mutations.clearViewState();
            offerStore.mutations.clearTargetOffer();
            offerStore.mutations.clearTargetProvider();
            this.$bvModal.hide("offers-modal");
        },
        onEdit() {
            offerStore.mutations.setViewState(VIEW_STATE.EDITING);
        },
    },
});

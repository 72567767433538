import Vue from 'vue';
import Construction from '@/assets/img/undraw/under-construction.svg';
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue';
export default Vue.extend({
    name: 'ComingSoon',
    components: {
        RouteBreadCrumb,
        Construction
    }
});

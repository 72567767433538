/* eslint-disable */
import { storeBuilder } from "@/store/RootState";
import Client from "../../service/Client";
import * as notificationStore from "./NotificationStore";
import { AvatarClient } from "@/service/AvatarClient";
/**
 * @description Base store state for handling avatars
 * @author Alejandro Nieto
 * @date 4/19/2022
 * @export
 * @class AvatarState
 */
export class AvatarState {
    loading = false;
    allAvatars = new Array();
}
// Avatar module builder
export const avatarModule = storeBuilder.module("avatar", new AvatarState());
// Getters
const getterFunctions = {
    getAllAvatars: avatarModule.read((state) => state.allAvatars, "getAllAvatars"),
    activeAvatars: avatarModule.read((state) => state.allAvatars.filter((av) => av.archived !== true), "activeAvatars"),
    inactiveAvatars: avatarModule.read((state) => state.allAvatars.filter((av) => av.archived === true), "inactiveAvatars"),
};
export const getters = {
    get getAllAvatars() {
        return getterFunctions.getAllAvatars();
    },
    get activeAvatars() {
        return getterFunctions.activeAvatars();
    },
    get inactiveAvatars() {
        return getterFunctions.inactiveAvatars();
    },
};
// Actions
async function getAllAvatars() {
    try {
        const avatars = await AvatarClient.getInstance().readAll();
        mutations.setAvatars(avatars);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error getting avatars.",
            error,
        });
    }
}
async function uploadAvatar(_, payload) {
    try {
        const resp = await Client.uploadImage(payload.file);
        notificationStore.actions.pushSuccess({
            title: "Avatar uploaded.",
        });
        return resp.data.secure_url;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error uploading avatar.",
            error,
        });
    }
}
async function createAvatar(_, payload) {
    try {
        await AvatarClient.getInstance().create(payload);
        notificationStore.actions.pushSuccess({
            title: "Avatar created.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error creating avatar.",
            error,
        });
    }
}
async function removeAvatar(_, avatarId) {
    try {
        await AvatarClient.getInstance().delete(avatarId);
        notificationStore.actions.pushSuccess({
            title: "Avatar removed.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error removing avatar.",
            error,
        });
    }
}
async function updateAvatar(_, avatar) {
    try {
        await AvatarClient.getInstance().update(avatar);
        notificationStore.actions.pushSuccess({
            title: "Avatar updated.",
        });
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Error updating avatar.",
            error,
        });
    }
}
export const actions = {
    getAllAvatars: avatarModule.dispatch(getAllAvatars),
    uploadAvatar: avatarModule.dispatch(uploadAvatar),
    createAvatar: avatarModule.dispatch(createAvatar),
    removeAvatar: avatarModule.dispatch(removeAvatar),
    updateAvatar: avatarModule.dispatch(updateAvatar),
};
// mutations
function setAvatars(state, avatars) {
    state.allAvatars = avatars;
}
export const mutations = {
    setAvatars: avatarModule.commit(setAvatars),
};

import Vue from 'vue';
export default Vue.extend({
    props: {
        title: {
            type: String,
            required: true
        },
        imgSrc: {
            type: String,
            required: true
        },
        selected: {
            type: [Boolean]
        },
        description: {
            type: String
        },
        example: {
            type: String
        }
    },
    computed: {
        nonSelected() {
            return this.selected === null;
        },
        selectedClass() {
            if (this.selected === null) {
                return '';
            }
            else if (this.selected) {
                return 'selected';
            }
            else {
                return 'non-selected';
            }
        }
    },
    methods: {
        onSelect() {
            this.$emit('onSelect');
        }
    }
});

import Vue from "vue";
import { ValidationProvider } from "vee-validate";
import * as offerStore from "@/store/modules/OfferStore";
import * as notificationStore from "@/store/modules/NotificationStore";
import BaseInput from "@/argon/components/Inputs/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import MediaContainer from "@/components/utils/MediaContainer.vue";
import URLVideoComponent from "@/components/utils/URLVideoComponent.vue";
import ElementUploadValidation from "@/components/utils/ElementUploadValidation.vue";
import moment from "moment";
import { DatePicker } from "element-ui";
import { VIEW_STATE } from "@/store/RootState";
export default Vue.extend({
    name: "DropDescription",
    components: {
        BaseInput,
        BaseTextArea,
        MediaContainer,
        URLVideoComponent,
        ValidationProvider,
        ElementUploadValidation,
        [DatePicker.name]: DatePicker,
    },
    data() {
        return {
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
                shortcuts: [
                    {
                        text: "In 1 Month",
                        onClick(picker) {
                            const date = moment().add(1, "months").toDate();
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "In 3 Months",
                        onClick(picker) {
                            const date = moment().add(3, "months").toDate();
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "In 6 Months",
                        onClick(picker) {
                            const date = moment().add(6, "months").toDate();
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "Never",
                        onClick(picker) {
                            picker.$emit("pick", null);
                        },
                    },
                ],
            },
        };
    },
    computed: {
        dropName: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.title;
            },
            set(val) {
                offerStore.mutations.setDropTitle(val);
            },
        },
        dropHeroImageUrl: {
            get() {
                return offerStore.getters.targetOffer?.heroUrl;
            },
            set(val) {
                offerStore.mutations.setDropHeroUrl(val);
            },
        },
        dropImageUrls: {
            get() {
                return offerStore.getters.targetOffer?.supporting_image_urls;
            },
            set(val) {
                offerStore.mutations.setDropImagesUrls(val);
            },
        },
        dropVideoUrl: {
            get() {
                return offerStore.getters.targetOffer?.video_url;
            },
            set(val) {
                offerStore.mutations.setDropVideoUrl(val);
            },
        },
        dropDescription: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.description;
            },
            set(val) {
                offerStore.mutations.setDropDescription(val);
            },
        },
        reducedPrice: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.value_reduced;
            },
            set(val) {
                offerStore.mutations.setDropDiscountReducedPrice(val);
            },
        },
        value: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.value;
            },
            set(val) {
                offerStore.mutations.setValue(val);
            },
        },
        dropLegal: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.legal;
            },
            set(val) {
                offerStore.mutations.setDropLegal(val);
            },
        },
        dropRedemptionInstructions: {
            get() {
                return offerStore.getters.targetOffer?.metadata
                    ?.redemption_instruct;
            },
            set(val) {
                offerStore.mutations.setDropRedemptionInstructions(val);
            },
        },
        dropRedemptionRequirements: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.redemption_req;
            },
            set(val) {
                offerStore.mutations.setDropRedemptionRequirements(val);
            },
        },
        dropExpirationDate: {
            get() {
                return offerStore.getters.targetOffer?.metadata?.expiration_date;
            },
            set(val) {
                console.log("val", val);
                offerStore.mutations.setDropExpirationDate(val);
            },
        },
        offer() {
            return offerStore.getters.targetOffer;
        },
        loading() {
            return offerStore.getters.loading;
        },
        isViewing() {
            return offerStore.getters.viewState === VIEW_STATE.VIEWING;
        },
        isUrlVideo() {
            const url = offerStore.getters.targetOffer?.video_url;
            return url?.includes("youtube") || url?.includes("vimeo");
        },
    },
    methods: {
        async handleImageUpload(mutation, payload) {
            try {
                const resp = await offerStore.actions.uploadOfferImage(payload);
                mutation(resp.data.secure_url);
                // offerStore.mutations.setDropHeroUrl(resp.data.secure_url)
            }
            catch (e) {
                notificationStore.actions.pushError({
                    title: "Error Uploading Image",
                    message: e.message,
                });
            }
        },
        async handleMainOfferImageUpload(payload) {
            this.handleImageUpload(offerStore.mutations.setDropHeroUrl, payload);
        },
        async handleDropImageUrlsUpload(payload) {
            this.handleImageUpload(offerStore.mutations.setDropImagesUrls, payload);
        },
        async handleOfferVideoUpload(payload) {
            this.handleImageUpload(offerStore.mutations.setDropVideoUrl, payload);
        },
        async handleYouTubeVideoInput(payload) {
            offerStore.mutations.setDropVideoUrl(payload);
        },
        async onRemoveMainOfferImage() {
            offerStore.mutations.setDropHeroUrl(null);
        },
        async onRemoveImageUrls() {
            offerStore.mutations.clearDropImagesUrls();
        },
        async onRemoveVideo() {
            offerStore.mutations.setDropVideoUrl(null);
        },
        // handles any validation of image
        beforeImageUpload(file) {
            // const { valid } = await this.$refs.heroImageProvider.validate(file)
            // offerStore.mutations.setOfferItemMedia()
            // basic image type validation
            const allowedExtension = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
                "image/bmp",
            ];
            if (allowedExtension.indexOf(file.type) < -1) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "File must be an image.",
                });
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "Image size cannot exceed 2MB in size.",
                });
                return false;
            }
            return true;
        },
        beforeVideoUpload(file) {
            const allowedExtension = ["video/mp4", "video/ogg", "video/webm"];
            if (allowedExtension.indexOf(file.type) < -1) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "File must be an MP4, OGG or WEBM video type.",
                });
                return false;
            }
            return true;
        },
    },
});

var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "label",
      {
        staticClass: "form-control-label",
        attrs: {
          for: "tags",
        },
      },
      [_vm._v(_vm._s(_vm.label))]
    ),
    _vm.dynamicTags.length
      ? _c(
          "div",
          {
            staticClass: "my-2",
          },
          _vm._l(_vm.dynamicTags, function (tag, index) {
            return _c(
              "el-tag",
              {
                key: tag + index,
                staticStyle: {
                  "text-transform": "capitalize",
                },
                attrs: {
                  size: "small",
                  type: _vm.tagType,
                  closable: true,
                  "close-transition": false,
                },
                on: {
                  close: function ($event) {
                    return _vm.handleClose(tag)
                  },
                },
              },
              [_vm._v(" " + _vm._s(tag) + " ")]
            )
          }),
          1
        )
      : _vm._e(),
    _vm.$attrs.type === "checkbox"
      ? _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              ref: "saveTagInput",
              staticClass: "form-control mb-4",
              attrs: {
                name: "tags",
                size: "mini",
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.inputValue)
                  ? _vm._i(_vm.inputValue, null) > -1
                  : _vm.inputValue,
              },
              on: {
                input: _vm.onInput,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.handleInputConfirm.apply(null, arguments)
                },
                blur: _vm.handleInputConfirm,
                change: function ($event) {
                  var $$a = _vm.inputValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputValue = $$c
                  }
                },
              },
            },
            "input",
            _vm.$attrs,
            false
          )
        )
      : _vm.$attrs.type === "radio"
      ? _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              ref: "saveTagInput",
              staticClass: "form-control mb-4",
              attrs: {
                name: "tags",
                size: "mini",
                type: "radio",
              },
              domProps: {
                checked: _vm._q(_vm.inputValue, null),
              },
              on: {
                input: _vm.onInput,
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.handleInputConfirm.apply(null, arguments)
                },
                blur: _vm.handleInputConfirm,
                change: function ($event) {
                  _vm.inputValue = null
                },
              },
            },
            "input",
            _vm.$attrs,
            false
          )
        )
      : _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputValue,
                  expression: "inputValue",
                },
              ],
              ref: "saveTagInput",
              staticClass: "form-control mb-4",
              attrs: {
                name: "tags",
                size: "mini",
                type: _vm.$attrs.type,
              },
              domProps: {
                value: _vm.inputValue,
              },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.inputValue = $event.target.value
                  },
                  _vm.onInput,
                ],
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  $event.preventDefault()
                  return _vm.handleInputConfirm.apply(null, arguments)
                },
                blur: _vm.handleInputConfirm,
              },
            },
            "input",
            _vm.$attrs,
            false
          )
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
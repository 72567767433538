import Vue from 'vue';
import OffersCardComponent from './OffersCardComponent.vue';
import * as offerStore from '@/store/modules/OfferStore';
export default Vue.extend({
    name: 'DiscountTypeSelect',
    components: { OffersCardComponent },
    props: {},
    data() {
        return {
            selectedItem: null
        };
    },
    mounted() {
        this.selectedItem = offerStore.getters.targetOffer?.sub_type;
    },
    computed: {
        targetOffer() {
            return offerStore.getters.targetOffer;
        },
        discountBogo() {
            return offerStore.getters.targetOffer?.sub_type === 'DISCOUNT_BOGO';
        },
        discountFree() {
            return offerStore.getters.targetOffer?.sub_type === 'DISCOUNT_FREE';
        },
        discountItem() {
            return offerStore.getters.targetOffer?.sub_type === 'DISCOUNT_ITEM';
        },
        discountTotal() {
            return offerStore.getters.targetOffer?.sub_type === 'DISCOUNT_TOTAL';
        }
    },
    methods: {
        offerDiscountTypeSelect(val) {
            this.selectedItem = val;
            offerStore.mutations.setDropDiscountType(val);
        }
    }
});

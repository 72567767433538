import { defineComponent, ref, computed, onBeforeUnmount } from "vue";
import LandingHeader from "../../components/landing/LandingHeader.vue";
import ContentFooter from "../../layout/ContentFooter.vue";
import ForgotPasswordModal from "@/modals/ForgotPasswordModal.vue";
import { useRouter } from "vue2-helpers/vue-router";
import * as authStore from "@/store/modules/AuthStore";
export default defineComponent({
    name: "Login",
    components: { LandingHeader, ContentFooter, ForgotPasswordModal },
    setup() {
        const emailAddress = ref("");
        const password = ref("");
        const loading = ref(false);
        const errorMessage = ref(null);
        const errorState = ref(false);
        const hasUser = computed(() => authStore.getters.getUser);
        const isAdmin = computed(() => authStore.getters.isAdmin);
        const isMerchant = computed(() => authStore.getters.isMerchant);
        const router = useRouter();
        const logIn = async () => {
            clear();
            try {
                errorState.value = false;
                loading.value = true;
                if (!emailAddress.value || !password.value) {
                    const err = new Error("Please enter your email address and password.");
                    throw err;
                }
                const email = emailAddress.value;
                const pass = password.value;
                await authStore.actions.loginAction({
                    emailAddress: email,
                    password: pass,
                });
                loading.value = false;
                router.push({ name: "Dashboard" });
            }
            catch (err) {
                errorMessage.value = err.message;
                errorState.value = true;
                loading.value = false;
            }
        };
        const clear = () => {
            errorMessage.value = null;
            errorState.value = false;
        };
        onBeforeUnmount(() => {
            clear();
        });
        return {
            emailAddress,
            password,
            loading,
            errorMessage,
            errorState,
            hasUser,
            isAdmin,
            isMerchant,
            logIn,
        };
    },
});

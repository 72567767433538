var render = function render() {
  var _class
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "header",
      class: ((_class = {}), (_class[`bg-${_vm.type}`] = _vm.type), _class),
    },
    [
      _c(
        "b-container",
        {
          attrs: {
            fluid: "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "header-body",
            },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "fade-transition",
    [
      _c(
        "b-alert",
        {
          class: [
            {
              "alert-dismissible": _vm.dismissible,
            },
          ],
          attrs: {
            variant: _vm.type,
            role: "alert",
            dismissible: _vm.dismissible,
          },
          on: {
            dismissed: function ($event) {
              return _vm.$emit("dismissed")
            },
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          !_vm.dismissible
            ? _vm._t("default")
            : [
                _vm.icon || _vm.$slots.icon
                  ? [
                      _vm._t("icon", function () {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "alert-icon",
                              attrs: {
                                "data-notify": "icon",
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: _vm.icon,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      }),
                    ]
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "alert-text",
                  },
                  [_vm._t("default")],
                  2
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
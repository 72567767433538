import Vue from 'vue';
import * as companyStore from '@/store/modules/CompanyStore';
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue';
import MerchantCompanyCard from '@/components/companies/MerchantCompanyCard.vue';
export default Vue.extend({
    name: 'CompanyDetails',
    components: {
        RouteBreadCrumb,
        MerchantCompanyCard
    },
    mounted() {
        // At page load, store's AuthAction will call getCompany and this initCompany will be overriden,
        // removing navigation to this page for users with a company ID as a workaround.
        companyStore.mutations.initCompany();
    }
});

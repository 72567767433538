import Vue from 'vue';
import * as gyftStore from '@/store/modules/GyftStore';
import * as authStore from '@/store/modules/AuthStore';
import RouteBreadCrumb from '@/argon/components/Breadcrumb/RouteBreadcrumb.vue';
export default Vue.extend({
    name: 'GyftPanel',
    components: {
        RouteBreadCrumb
    },
    mounted() {
        gyftStore.actions.getAccount();
    },
    computed: {
        isAdmin() {
            return authStore.getters.isAdmin;
        },
        gyftAccount() {
            return gyftStore.getters.getAccount;
        }
    },
    filters: {
        normalizeText(text) {
            return text
                .split('_')
                .join(' ')
                .replace(/([A-Z])/g, ' $1');
        },
        numberToLocaleDateString(number) {
            return new Date(number).toLocaleDateString();
        }
    }
});

import CustomHeader from "../../components/landing/LandingHeader.vue";
import ContentFooter from "@/layout/ContentFooter.vue";
import BaseInput from "@/argon/components/Inputs/BaseInput.vue";
import { ValidationObserver } from "vee-validate";
import * as authStore from "@/store/modules/AuthStore";
export default {
    name: "CreateMerchantAccount",
    components: { BaseInput, CustomHeader, ContentFooter, ValidationObserver },
    data() {
        return {
            email: "",
            password: "",
            first: "",
            last: "",
            phone: "",
            website: "",
            error: false,
            success: false,
            title: "",
            message: "",
        };
    },
    computed: {
        loading() {
            return authStore.getters.getLoading;
        },
    },
    methods: {
        async createAccount() {
            this.error = false;
            this.success = false;
            const userData = {
                role: "MERCHANT",
                email: this.email,
                password: this.password,
                first: this.first,
                last: this.last,
                phone: this.phone,
                website: this.website,
            };
            try {
                await authStore.actions.createUserAction(userData);
                this.success = true;
                this.title = "Account created!";
                this.message =
                    "Please check your email for a verification link.";
            }
            catch (err) {
                this.error = true;
                this.title = "Unable to create account.";
                this.message = err.message;
            }
        },
    },
};

import Vue from "vue";
import { ValidationObserver } from "vee-validate";
import * as gcStore from "@/store/modules/GiftCardStore";
import * as companyStore from "@/store/modules/CompanyStore";
import GiftCard from "@/argon/components/Cards/GiftCardPreview.vue";
import BaseInput from "@/argon/components/Inputs/BaseInput.vue";
import FindOrCreateCompany from "../components/companies/FindOrCreateCompany.vue";
import { VIEW_STATE } from "@/store/RootState";
export default Vue.extend({
    name: "GiftCardModal",
    data() {
        return {
            showBarcode: false,
            showSecondary: false,
        };
    },
    beforeDestroy() {
        gcStore.mutations.clearViewState();
    },
    components: {
        ValidationObserver,
        FindOrCreateCompany,
        GiftCard,
        BaseInput,
    },
    computed: {
        targetCard() {
            return gcStore.getters.targetCard;
        },
        loading() {
            return gcStore.getters.loading;
        },
        editing() {
            return gcStore.getters.viewState === VIEW_STATE.EDITING;
        },
        company() {
            return companyStore.getters.getAuthUserCompany;
        },
    },
    methods: {
        modalShown() {
            this.showBarcode = this.targetCard?.barcode ?? false;
            this.showSecondary = this.targetCard?.subNumber ?? false;
        },
        secondarySwitch(val) {
            if (val) {
                gcStore.mutations.createCardSubNumber();
            }
            else {
                gcStore.mutations.clearCardSubNumber();
            }
        },
        barcodeSwitch(val) {
            if (val) {
                gcStore.mutations.setBarcodeNumber("");
            }
            else {
                gcStore.mutations.clearCardSubNumber();
            }
        },
        updateCardNumber(val) {
            gcStore.mutations.setCardNumber(val);
        },
        updateCardBalance(val) {
            gcStore.mutations.setCardValue(val);
        },
        updateCardSubLabel(val) {
            gcStore.mutations.setCardSubNumberLabel(val);
        },
        updateCardSubNumber(val) {
            gcStore.mutations.setCardSubNumber(val);
        },
        updateBarcode(val) {
            gcStore.mutations.setBarcodeNumber(val);
        },
        async handleSave() {
            this.$bvModal.hide("gift-card-modal");
            if (this.editing) {
                await gcStore.actions.updateGiftCard(this.targetCard);
            }
            else {
                await gcStore.actions.createGiftCard(this.targetCard);
            }
        },
        onClose() {
            this.$bvModal.hide("gift-card-modal");
        },
    },
});

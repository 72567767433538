import Vue from "vue";
import * as avatarStore from "@/store/modules/AvatarStore";
import BaseHeader from "@/argon/components/BaseHeader.vue";
import RouteBreadCrumb from "@/argon/components/Breadcrumb/RouteBreadcrumb.vue";
import ElementUploadValidation from "@/components/utils/ElementUploadValidation.vue";
import * as notificationStore from "@/store/modules/NotificationStore";
export default Vue.extend({
    name: "AvatarManager",
    components: { BaseHeader, RouteBreadCrumb, ElementUploadValidation },
    data() {
        return {
            newAvatarImageUrl: null,
            newAvatarTitle: null,
            imageValid: true,
            isHovered: false,
        };
    },
    computed: {
        activeAvatars() {
            return avatarStore.getters.activeAvatars;
        },
        inactiveAvatars() {
            return avatarStore.getters.inactiveAvatars;
        },
    },
    async mounted() {
        await avatarStore.actions.getAllAvatars();
    },
    methods: {
        handleHover(hovered) {
            this.isHovered = hovered;
        },
        async handleAvatarUpload(payload) {
            this.newAvatarImageUrl =
                await avatarStore.actions.uploadAvatar(payload);
            console.log(this.newAvatarImageUrl);
        },
        beforeImageUpload(file) {
            // const { valid } = await this.$refs.heroImageProvider.validate(file)
            // offerStore.mutations.setOfferItemMedia()
            // basic image type validation
            const allowedExtension = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
                "image/bmp",
            ];
            if (allowedExtension.indexOf(file.type) < -1) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "File must be an image.",
                });
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "Image size cannot exceed 2MB in size.",
                });
                return false;
            }
            return true;
        },
        archive(avatar) {
            this.$events.$emit("showConfirmationModal", {
                title: "Archive Avatar",
                icon: "exclamation-triangle",
                iconVariant: "warning",
                headerText: "Are you sure you want to archive this Avatar?",
                bodyText: "",
                confirmText: "Confirm",
                cancelText: "Cancel",
                confirmAction: async () => {
                    this.$events.$emit("showLoading");
                    try {
                        const newAv = Object.assign({}, avatar);
                        newAv.archived = true;
                        await avatarStore.actions.updateAvatar(newAv);
                        await avatarStore.actions.getAllAvatars();
                        this.$events.$emit("hideLoading");
                    }
                    catch (err) {
                        this.$events.$emit("hideLoading");
                        this.$events.$emit("error", err);
                    }
                },
            });
        },
        restore(avatar) {
            this.$events.$emit("showConfirmationModal", {
                title: "Restore Avatar",
                icon: "exclamation-triangle",
                iconVariant: "warning",
                headerText: "Are you sure you want to restore this Avatar?",
                bodyText: "",
                confirmText: "Confirm",
                cancelText: "Cancel",
                confirmAction: async () => {
                    this.$events.$emit("showLoading");
                    try {
                        const newAv = Object.assign({}, avatar);
                        newAv.archived = false;
                        await avatarStore.actions.updateAvatar(newAv);
                        await avatarStore.actions.getAllAvatars();
                        this.$events.$emit("hideLoading");
                    }
                    catch (err) {
                        this.$events.$emit("hideLoading");
                        this.$events.$emit("error", err);
                    }
                },
            });
        },
        remove(avatarId) {
            this.$events.$emit("showConfirmationModal", {
                title: "Remove Avatar",
                icon: "exclamation-triangle",
                iconVariant: "warning",
                headerText: "Are you sure you want remove this Avatar?",
                bodyText: "This action can't be undone",
                confirmText: "Confirm",
                cancelText: "Cancel",
                confirmAction: async () => {
                    this.$events.$emit("showLoading");
                    try {
                        await avatarStore.actions.removeAvatar(avatarId);
                        await avatarStore.actions.getAllAvatars();
                        this.$events.$emit("hideLoading");
                    }
                    catch (err) {
                        this.$events.$emit("hideLoading");
                        this.$events.$emit("error", err);
                    }
                },
            });
        },
        async createNewAvatar() {
            const avatar = {
                imageUrl: this.newAvatarImageUrl,
                title: this.newAvatarTitle,
            };
            this.$events.$emit("showLoading");
            try {
                await avatarStore.actions.createAvatar(avatar);
                await avatarStore.actions.getAllAvatars();
                this.newAvatarTitle = null;
                this.removeAvatarImage();
                this.$events.$emit("hideLoading");
            }
            catch (err) {
                this.$events.$emit("hideLoading");
                this.$events.$emit("error", err);
            }
        },
        removeAvatarImage() {
            // this.$refs.file.type = "file"
            this.newAvatarImageUrl = null;
        },
    },
});

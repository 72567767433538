import Vue from "vue";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, } from "element-ui";
import moment from "moment";
import * as campaignStore from "@/store/modules/CampaignStore";
import * as authStore from "@/store/modules/AuthStore";
import { USER_ROLES, Utils } from "@gd-mono/gd-common";
export default Vue.extend({
    name: "CampaignListTable",
    props: {
        hasCompanyColumn: {
            type: Boolean,
            default: true,
        },
        hasActions: {
            type: Boolean,
            default: true,
        },
        companyId: {
            type: String,
            default: "",
        },
    },
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
    },
    data() {
        return {
            showModal: false,
            showArchived: false,
            searchTerms: "",
            campaignData: new Array(),
            modalData: {
                archive: {
                    title: "Archive Campaign",
                    icon: "exclamation-triangle",
                    iconVariant: "info",
                    headerText: "Are you sure you want to Archive this Campaign?",
                    bodyText: "This action can't be undone.",
                    confirmText: "Archive Campaign",
                    cancelText: "Cancel",
                },
                pause: {
                    title: "Pause Campaign",
                    icon: "exclamation-triangle",
                    iconVariant: "info",
                    headerText: "Are you sure you want to Pause this Campaign?",
                    bodyText: "It will not be shown to users while paused.",
                    confirmText: "Pause Campaign",
                    cancelText: "Cancel",
                },
            },
        };
    },
    async mounted() {
        await this.getAllCampaigns();
        this.campaignData = this.allCampaigns;
        this.filter();
    },
    computed: {
        utils() {
            return Utils;
        },
        moment() {
            return moment;
        },
        loading() {
            return campaignStore.getters.getLoading;
        },
        allCampaigns() {
            return campaignStore.getters.allCampaigns;
        },
        allCampaignsUnarchived() {
            return campaignStore.getters.allCampaignsUnarchived;
        },
        resultingCampaigns() {
            if (this.showArchived)
                return this.campaignData;
            return this.campaignData.filter((c) => !c.archived);
        },
        computedStatus() {
            return (campaign) => campaignStore.getters.getComputedStatus(campaign);
        },
        authUserIsAdmin() {
            return authStore.getters.getUser.role === USER_ROLES.ADMIN;
        },
    },
    watch: {
        searchTerms() {
            this.filter();
        },
    },
    methods: {
        async getAllCampaigns() {
            return await campaignStore.actions.getAllCampaigns();
        },
        async archiveCampaignAction(campaign) {
            return await campaignStore.actions.archiveCampaign(campaign);
        },
        async pauseCampaignAction(campaign) {
            return await campaignStore.actions.pauseCampaign(campaign);
        },
        async getAllCampaignsMethod() {
            await this.getAllCampaigns();
            this.campaignData = this.allCampaigns;
        },
        filter() {
            let filteredCampaigns = this.allCampaigns;
            if (this.searchTerms) {
                filteredCampaigns = filteredCampaigns.filter((campaign) => {
                    return (`${campaign.name.toLowerCase()} ${campaign.offer?.metadata?.title.toLowerCase()}`.search(`${this.searchTerms.toLowerCase()}`) !== -1);
                });
            }
            if (this.companyId) {
                filteredCampaigns = filteredCampaigns.filter((campaign) => {
                    return campaign.companyId === this.companyId;
                });
            }
            this.campaignData = filteredCampaigns;
        },
        showArchivedAction() {
            this.showArchived = !this.showArchived;
        },
        methodDate(date) {
            return moment(date).format("MM/DD h:mm a");
        },
        methodTotalPickedUp(campaign) {
            // TODO FIX
            // return campaign.redeem.totalRedemptions - campaign.numRemaining
            return 0;
        },
        methodRemainingProgress(campaign) {
            // TODO FIX
            // const progress =
            //     ((campaign.redeem.totalRedemptions - campaign.numRemaining) /
            //         campaign.redeem.totalRedemptions) *
            //     100
            // return Math.floor(progress)
            return 0;
        },
        methodDropType(campaign) {
            return campaignStore.getters.getComputedDropType(campaign);
        },
        async handleCommand(commandObject) {
            if (!commandObject) {
                return;
            }
            switch (commandObject.action) {
                case "archive":
                    this.$events.$emit("showConfirmationModal", {
                        title: this.modalData.archive.title,
                        icon: this.modalData.archive.icon,
                        iconVariant: this.modalData.archive.iconVariant,
                        headerText: this.modalData.archive.headerText,
                        bodyText: this.modalData.archive.bodyText,
                        confirmText: this.modalData.archive.confirmText,
                        confirmAction: async () => this.archiveCampaign(commandObject.campaign),
                        cancelText: this.modalData.archive.cancelText,
                    });
                    break;
                case "pause":
                    // already paused, just unpause
                    if (commandObject.campaign.paused) {
                        await this.pauseCampaign(commandObject.campaign);
                        return;
                    }
                    this.$events.$emit("showConfirmationModal", {
                        title: this.modalData.pause.title,
                        icon: this.modalData.pause.icon,
                        iconVariant: this.modalData.pause.iconVariant,
                        headerText: this.modalData.pause.headerText,
                        bodyText: this.modalData.pause.bodyText,
                        confirmText: this.modalData.pause.confirmText,
                        confirmAction: async () => this.pauseCampaign(commandObject.campaign),
                        cancelText: this.modalData.pause.cancelText,
                    });
                    break;
                case "details":
                    // push via router to campaign details page
                    this.$router.push(`/portal/campaigns/${commandObject.campaign.id}`);
                    break;
            }
        },
        routeToCampaignDetails(campaign) {
            this.$router.push(`/portal/campaigns/${campaign.id}`);
        },
        async archiveCampaign(campaign) {
            await this.archiveCampaignAction(campaign);
            await this.getAllCampaignsMethod();
        },
        async pauseCampaign(campaign) {
            await this.pauseCampaignAction(campaign);
            await this.getAllCampaignsMethod();
        },
    },
});

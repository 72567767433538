import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/auth';
import { ValidationObserver } from 'vee-validate';
import BaseInput from '@/argon/components/Inputs/BaseInput.vue';
export default Vue.extend({
    name: 'ForgotPasswordModal',
    components: { ValidationObserver, BaseInput },
    data() {
        return {
            loading: false,
            sendSuccess: false,
            sendFail: false,
            sendFailError: '',
            forgotPasswordEmail: ''
        };
    },
    methods: {
        async sendPasswordResetEmail() {
            this.clear();
            this.loading = true;
            try {
                await firebase.auth().sendPasswordResetEmail(this.forgotPasswordEmail);
                this.sendSuccess = true;
            }
            catch (err) {
                this.sendFail = true;
                this.sendFailError = err.message;
            }
            this.loading = false;
        },
        clear() {
            this.sendSuccess = false;
            this.sendFail = false;
            this.sendFailError = '';
        }
    },
    beforeDestroy() {
        this.clear();
    }
});

import Vue from 'vue';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
import * as companyStore from '@/store/modules/CompanyStore';
import * as authStore from '@/store/modules/AuthStore';
import { USER_ROLES } from '@gd-mono/gd-common';
export default Vue.extend({
    components: {
        Table,
        TableColumn,
        DropdownMenu,
        DropdownItem,
        Dropdown
    },
    props: {
        isPending: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        // Cant specify User type because there are two different ones in @gd/common
        members() {
            return this.isPending
                ? companyStore.getters.getCompany.pendingMembers
                : companyStore.getters.getCompany.members;
        },
        companyOwnerId() {
            return companyStore.getters.getCompany.ownerUserId;
        },
        currentUser() {
            return authStore.getters.getUser;
        },
        isAdmin() {
            return authStore.getters.isAdmin;
        },
        isOwnerOrAdmin() {
            return (this.currentUser.role === USER_ROLES.ADMIN ||
                this.currentUser.id === this.companyOwnerId);
        },
        loading() {
            return companyStore.getters.getLoading;
        }
    },
    methods: {
        tableRowClassName({ row }) {
            if (row.blocked === true)
                return 'danger-row';
            return '';
        },
        filterHandler(value, row, column) {
            const property = column['property'];
            return row[property] === value;
        },
        async handleCommand(commandObject) {
            if (!commandObject) {
                return;
            }
            switch (commandObject.action) {
                case 'accept':
                    companyStore.actions.acceptCompanyMember(commandObject.userId);
                    break;
                case 'remove':
                    this.$events.$emit('showConfirmationModal', {
                        title: 'Remove Member',
                        icon: 'exclamation-triangle',
                        iconVariant: 'warning',
                        headerText: 'Are you sure you want to remove this member?',
                        bodyText: 'This member will no longer be able to access the company.',
                        confirmText: 'Confirm',
                        cancelText: 'Cancel',
                        confirmAction: async () => {
                            this.$events.$emit('showLoading');
                            await companyStore.actions.removeMember(commandObject.userId);
                            this.$events.$emit('hideLoading');
                        }
                    });
                    break;
                case 'make-owner':
                    this.$events.$emit('showConfirmationModal', {
                        title: 'Make Owner',
                        icon: 'exclamation-triangle',
                        iconVariant: 'warning',
                        headerText: 'Are you sure you want to make this member the owner?',
                        bodyText: 'This member will be able to manage the company.',
                        confirmText: 'Confirm',
                        cancelText: 'Cancel',
                        confirmAction: async () => {
                            this.$events.$emit('showLoading');
                            await companyStore.actions.updateOwner(commandObject.userId);
                            this.$events.$emit('hideLoading');
                        }
                    });
                    break;
                case 'go-to-account':
                    this.$router.push(`/portal/users/${commandObject.userId}`);
                    break;
            }
        }
    }
});

import Vue from 'vue';
import CompanyListTable from '@/components/companies/CompanyListTable.vue';
import * as authStore from '@/store/modules/AuthStore';
export default Vue.extend({
    name: 'FindOrCreateCompany',
    components: { CompanyListTable },
    data() {
        return {
            showFind: false
        };
    },
    methods: {
        selectCompany(company) {
            this.$emit('selectCompany', company);
        }
    },
    computed: {
        isAdmin() {
            return authStore.getters.isAdmin;
        }
    }
});

import Vue from "vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import TagsInput from "@/argon/components/Inputs/TagsInput.vue";
import BaseInput from "@/argon/components/Inputs/BaseInput.vue";
import BaseTextArea from "@/components/BaseTextArea.vue";
import ImageUpload from "@/components/utils/ImageUpload.vue";
import CompanyLocations from "./CompanyLocations.vue";
import FindOrCreateCompany from "./FindOrCreateCompany.vue";
import ElementUploadValidation from "@/components/utils/ElementUploadValidation.vue";
import * as userStore from "@/store/modules/UserStore";
import * as authStore from "@/store/modules/AuthStore";
import * as companyStore from "@/store/modules/CompanyStore";
import * as notificationStore from "@/store/modules/NotificationStore";
import * as appStore from "@/store/modules/AppStore";
import { COMPANY_VIEW_STATE } from "@/store/modules/CompanyStore";
export default Vue.extend({
    name: "MerchantCompanyCard",
    components: {
        ImageUpload,
        ValidationProvider,
        ValidationObserver,
        CompanyLocations,
        FindOrCreateCompany,
        TagsInput,
        BaseInput,
        BaseTextArea,
        ElementUploadValidation,
    },
    data() {
        return {
            tags: [],
        };
    },
    computed: {
        company() {
            return companyStore.getters.getCompany;
        },
        authUser() {
            return authStore.getters.getUser;
        },
        isAdmin() {
            return authStore.getters.isAdmin;
        },
        isMerchantOwner() {
            return (authStore.getters.isMerchant &&
                this.authUser.companyId === this.company?.id);
        },
        editing() {
            return companyStore.getters.isEditing;
        },
        creating() {
            return companyStore.getters.isCreating;
        },
        viewing() {
            return companyStore.getters.isViewing;
        },
        loading() {
            return (companyStore.getters.getLoading || userStore.getters.getLoading);
        },
        showFindOrCreateCompany() {
            return !this.company && !this.$route.path.includes("/companies/new");
        },
        companyLogo: {
            get() {
                return companyStore.getters.getCompany?.logoUrl;
            },
            set(val) {
                companyStore.mutations.setLogoUrl(val);
            },
        },
        companyCover: {
            get() {
                return companyStore.getters.getCompany?.cover_url;
            },
            set(val) {
                companyStore.mutations.setCoverUrl(val);
            },
        },
    },
    methods: {
        async handleImageUpload(mutation, payload) {
            try {
                const resp = await appStore.actions.uploadOfferImage(payload);
                mutation(resp.data.secure_url);
                // offerStore.mutations.setDropHeroUrl(resp.data.secure_url)
            }
            catch (e) {
                notificationStore.actions.pushError({
                    title: "Error Uploading Image",
                    message: e.message,
                });
            }
        },
        async handleLogoUpload(payload) {
            this.handleImageUpload(companyStore.mutations.setLogoUrl, payload);
        },
        async handleCompanyCover(payload) {
            this.handleImageUpload(companyStore.mutations.setCoverUrl, payload);
        },
        beforeImageUpload(file) {
            // const { valid } = await this.$refs.heroImageProvider.validate(file)
            // offerStore.mutations.setOfferItemMedia()
            // basic image type validation
            const allowedExtension = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/gif",
                "image/bmp",
            ];
            if (allowedExtension.indexOf(file.type) < -1) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "File must be an image.",
                });
                return false;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                notificationStore.actions.pushError({
                    title: "Error",
                    message: "Image size cannot exceed 2MB in size.",
                });
                return false;
            }
            return true;
        },
        setEditing() {
            companyStore.mutations.setViewState(COMPANY_VIEW_STATE.EDITING);
        },
        setViewing() {
            companyStore.mutations.setViewState(COMPANY_VIEW_STATE.VIEWING);
        },
        setName(val) {
            companyStore.mutations.setName(val);
        },
        setLogoUrl(val) {
            companyStore.mutations.setLogoUrl(val);
        },
        setCoverUrl(val) {
            companyStore.mutations.setCoverUrl(val);
        },
        setCompanyUrl(val) {
            companyStore.mutations.setUrl(val);
        },
        setTwitterUrl(val) {
            companyStore.mutations.setTwitterUrl(val);
        },
        setFacebookUrl(val) {
            companyStore.mutations.setFacebookUrl(val);
        },
        setInstaUrl(val) {
            companyStore.mutations.setInstaUrl(val);
        },
        setDescription(val) {
            companyStore.mutations.setDescription(val);
        },
        setPhone(val) {
            companyStore.mutations.setPhone(val);
        },
        setAddress(val) {
            companyStore.mutations.setAddress(val);
        },
        setEmail(val) {
            companyStore.mutations.setEmail(val);
        },
        setTags(val) {
            companyStore.mutations.setTags(val);
        },
        async handleSave() {
            // if creating send create request, if editing, send put request
            if (this.creating) {
                await companyStore.actions.createCompany(authStore.getters.getUser.id);
                // alejandro@08/04/2022 - re-fetch user to get updated companyId
                await authStore.actions.getUserAction();
            }
            else if (this.editing) {
                await companyStore.actions.updateCompany();
            }
        },
        removeImage() {
            this.setLogoUrl(null);
        },
        removeCover() {
            this.setCoverUrl(null);
        },
        initCompany() {
            companyStore.mutations.initCompany();
        },
    },
});

import Vue from 'vue';
import Notification from './Notification.vue';
import { SlideYUpTransition } from 'vue2-transitions';
import * as NotificationStore from '@/store/modules/NotificationStore';
export default Vue.extend({
    components: {
        SlideYUpTransition,
        Notification
    },
    props: {
        transitionDuration: {
            type: Number,
            default: 200
        },
        overlap: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        notifications() {
            return NotificationStore.getters.getNotifications;
        }
    },
    methods: {
        removeNotification(timestamp) {
            NotificationStore.actions.removeNotificationAction(timestamp);
        }
    }
});

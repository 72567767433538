// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@/assets/img/parallax4.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".login-page[data-v-9bef9606] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.login-page .navbar-logo-container[data-v-9bef9606] {\n  width: 100%;\n  height: 100%;\n  -webkit-box-orient: horizontal;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: row;\n          flex-direction: row;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  text-align: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-size: 1.5em;\n  color: white;\n}\n.login-page .navbar-logo[data-v-9bef9606] {\n  -o-object-fit: contain;\n     object-fit: contain;\n  height: 50px;\n  width: 100%;\n  margin-bottom: 1em;\n}\n.nav-link[data-v-9bef9606] {\n  color: white !important;\n  z-index: 100;\n}", "",{"version":3,"sources":["webpack://./src/pages/landing/Login.vue"],"names":[],"mappings":"AACA;EACI,yDAAA;AAAJ;AAGI;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EAAA,6BAAA;MAAA,uBAAA;UAAA,mBAAA;EACA,wBAAA;MAAA,qBAAA;UAAA,uBAAA;EACA,kBAAA;EACA,yBAAA;MAAA,sBAAA;UAAA,mBAAA;EACA,gBAAA;EACA,YAAA;AADR;AAII;EACI,sBAAA;KAAA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;AAFR;AAMA;EACI,uBAAA;EACA,YAAA;AAHJ","sourcesContent":["\n.login-page {\n    background-image: url('@/assets/img/parallax4.jpg');\n\n    // background-size: cover;\n    .navbar-logo-container {\n        width: 100%;\n        height: 100%;\n        flex-direction: row;\n        justify-content: center;\n        text-align: center;\n        align-items: center;\n        font-size: 1.5em;\n        color: white;\n    }\n\n    .navbar-logo {\n        object-fit: contain;\n        height: 50px;\n        width: 100%;\n        margin-bottom: 1em;\n    }\n}\n\n.nav-link {\n    color: white !important;\n    z-index: 100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

import Vue from 'vue';
import * as authStore from '@/store/modules/AuthStore';
export default Vue.extend({
    name: 'sidebar',
    props: {
        title: {
            type: String,
            default: 'Merchant Portal'
        },
        shortTitle: {
            type: String,
            default: 'CT'
        },
        logo: {
            type: String,
            default: '@/assets/img/gd-logo.png'
        },
        backgroundColor: {
            type: String,
            default: 'vue',
            validator: (value) => {
                let acceptedValues = ['', 'vue', 'blue', 'green', 'orange', 'red', 'primary'];
                return acceptedValues.indexOf(value) !== -1;
            }
        },
        sidebarLinks: {
            type: Array,
            default: () => []
        },
        autoClose: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isAdmin() {
            return authStore.getters.isAdmin;
        }
    },
    provide() {
        return {
            autoClose: this.autoClose
        };
    },
    methods: {
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        }
    },
    mounted() {
        this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
        this.minimizeSidebar();
    },
    beforeDestroy() {
        if (this.$sidebar.showSidebar) {
            this.$sidebar.showSidebar = false;
        }
    }
});

import Vue from 'vue';
import { LazyYoutube, LazyVimeo } from 'vue-lazytube';
export default Vue.extend({
    components: {
        LazyYoutube,
        LazyVimeo
    },
    name: 'URLVideoComponent',
    props: {
        url: {
            type: String,
            required: true
        }
    },
    computed: {
        isYouTube() {
            return this.url.includes('youtube');
        },
        isVimeo() {
            return this.url.includes('vimeo');
        }
    }
});

import Vue from "vue";
export default Vue.extend({
    inheritAttrs: false,
    name: "GiftCard",
    props: {
        number: {
            type: String,
            required: false,
            default: "1234123412341234",
        },
        quantity: {
            type: Number,
            required: false,
            default: 1,
        },
        value: {
            type: String,
            required: false,
            default: "0",
        },
        status: {
            type: String,
            required: false,
            default: "New",
        },
        statusType: {
            type: String,
            required: false,
            default: "success",
        },
        expire: {
            type: String,
            required: false,
            default: "12/2026",
        },
        logoUrl: {
            type: String,
            required: false,
            default: "https://picsum.photos/200/300",
        },
        companyName: {
            type: String,
            required: false,
            default: "Company Name",
        },
        skew: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
});

export default {
    name: 'BasicModal',
    props: ['basicModal'],
    data() {
        return {};
    },
    methods: {
        confirm: async function () {
            if (this.basicModal.callback) {
                this.basicModal.callback(true, this);
            }
            this.$parent.closeBasicModal();
        },
        cancel: async function () {
            if (this.basicModal.callback) {
                this.basicModal.callback(false, this);
            }
            this.$parent.closeBasicModal();
        }
    }
};

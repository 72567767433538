import Vue from "vue";
import { ValidationProvider } from "vee-validate";
import { SlideYUpTransition } from "vue2-transitions";
import SelectProviderTable from "./SelectProviderTable.vue";
import GiftCardSourceSelect from "./GiftCardSourceSelect.vue";
import DropSourceTypeSelect from "./DropSourceTypeSelect.vue";
import DropDescription from "./DropDescription.vue";
import DiscountTypeSelect from "./DiscountTypeSelect.vue";
import OffersCardComponent from "./OffersCardComponent.vue";
import OfferTypePreview from "./OfferTypePreview.vue";
import InventoryUploadAlert from "./InventoryUploadAlert.vue";
import * as offerStore from "@/store/modules/OfferStore";
import { VIEW_STATE } from "@/store/RootState";
export default Vue.extend({
    components: {
        SelectProviderTable,
        SlideYUpTransition,
        OfferTypePreview,
        DropSourceTypeSelect,
        DropDescription,
        GiftCardSourceSelect,
        DiscountTypeSelect,
        OffersCardComponent,
        ValidationProvider,
        InventoryUploadAlert,
    },
    data() {
        return {
            selectedItem: null,
            selectedSource: null,
            progress: 0,
        };
    },
    beforeDestroy() {
        offerStore.mutations.clearViewState();
    },
    mounted() {
        this.selectedItem = this.targetOffer?.type || null;
        this.selectedSource = this.targetOffer?.inventory_type || null;
        this.progress = this.targetOffer?.inventory_type ? 1 : 0;
    },
    computed: {
        targetOffer() {
            return offerStore.getters.targetOffer;
        },
        offerStore() {
            return offerStore;
        },
        targetProvider() {
            return offerStore.getters.targetProvider;
        },
        loading() {
            return offerStore.getters.loading;
        },
        editing() {
            return offerStore.getters.viewState === VIEW_STATE.EDITING;
        },
        viewing() {
            return offerStore.getters.viewState === VIEW_STATE.VIEWING;
        },
        selected() {
            return this.selectedItem !== null && this.selectedSource !== null;
        },
    },
    methods: {
        offerTypeChange(option) {
            // return if not editing or creating
            if (this.viewing)
                return;
            this.selectedItem = option;
            offerStore.mutations.setOfferType(option);
        },
        offerSourceChange(option) {
            // return if not editing or creating
            if (this.viewing)
                return;
            this.selectedSource = option.source;
            offerStore.mutations.setTargetOfferInventoryType(option.source);
            offerStore.mutations.setTargetOfferSubType(option.type);
        },
        async handleSave() {
            await offerStore.actions.createOffer();
            this.$bvModal.hide("offers-modal");
        },
        next() {
            this.progress = this.progress + 1;
        },
    },
});

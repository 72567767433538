import Vue from 'vue';
import VueMarkdown from 'vue-markdown-render';
import Changelog from './../../CHANGELOG.md';
export default Vue.extend({
    name: 'ChangelogModal',
    components: {
        VueMarkdown
    },
    computed: {
        changelog() {
            return Changelog;
        }
    }
});

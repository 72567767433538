import Client from '@/service/Client';
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown } from 'element-ui';
export default {
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu
    },
    data() {
        return {
            since: new Date().getTime() - 90 * 86400000,
            feedback: []
        };
    },
    async mounted() {
        const feedbackArray = [];
        const response = await Client.getSiteMetrics(this.since);
        const feedback = response.data.data.metrics.feedback;
        feedback.forEach((feedback) => feedbackArray.push(...feedback.messages));
        this.feedback = feedbackArray;
    }
};

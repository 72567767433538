var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    _vm.tag,
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropDown,
          expression: "closeDropDown",
        },
      ],
      tag: "component",
      class: [
        {
          show: _vm.isOpen,
        },
        `drop${_vm.direction}`,
      ],
      on: {
        click: _vm.toggleDropDown,
      },
    },
    [
      _vm._t(
        "title-container",
        function () {
          return [
            _c(
              _vm.titleTag,
              {
                tag: "component",
                staticClass: "btn-rotate",
                class: [
                  {
                    "dropdown-toggle": _vm.hasToggle,
                  },
                  _vm.titleClasses,
                ],
                attrs: {
                  "aria-expanded": _vm.isOpen,
                  "data-toggle": "dropdown",
                },
              },
              [
                _vm._t(
                  "title",
                  function () {
                    return [
                      _c("i", {
                        class: _vm.icon,
                      }),
                      _vm._v(" " + _vm._s(_vm.title) + " "),
                    ]
                  },
                  {
                    isOpen: _vm.isOpen,
                  }
                ),
              ],
              2
            ),
          ]
        },
        {
          isOpen: _vm.isOpen,
        }
      ),
      _c(
        "ul",
        {
          staticClass: "dropdown-menu",
          class: [
            {
              show: _vm.isOpen,
            },
            {
              "dropdown-menu-right": _vm.menuOnRight,
            },
            _vm.menuClasses,
          ],
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import GyftProviderClient from "@/service/GyftProviderClient";
import { storeBuilder, VIEW_STATE } from "@/store/RootState";
import { OfferModel, } from "@gd-mono/gd-common";
import { OfferClient } from "../../service/OfferClient";
import Client from "@/service/Client";
import * as notificationStore from "./NotificationStore";
import axios from "axios";
/**
 * @description Initial Offer Store State
 * @author Logan Hendershot
 * @date 11/23/2021
 * @export
 * @class OfferState
 */
export class OfferState {
    viewState = VIEW_STATE.VIEWING;
    loading = false;
    targetOffer = undefined;
    targetProvider = undefined;
    allOffers = undefined;
    allProviders = undefined;
}
// Offer module builder
const OfferModule = storeBuilder.module("offer", new OfferState());
// Offer getter functions object
const getterFunctions = {
    loading: OfferModule.read((state) => state.loading, "loading"),
    viewState: OfferModule.read((state) => state.viewState, "viewState"),
    targetOffer: OfferModule.read((state) => {
        return state.targetOffer;
    }, "targetOffer"),
    targetProvider: OfferModule.read((state) => {
        return state.targetProvider;
    }, "targetProvider"),
    allOffers: OfferModule.read((state) => state.allOffers, "allOffers"),
    allProviders: OfferModule.read((state) => state.allProviders, "allProviders"),
};
// Offer module getters
export const getters = {
    get loading() {
        return getterFunctions.loading();
    },
    get viewState() {
        return getterFunctions.viewState();
    },
    get targetOffer() {
        return getterFunctions.targetOffer();
    },
    get allOffers() {
        return getterFunctions.allOffers();
    },
    get allProviders() {
        return getterFunctions.allProviders();
    },
    get targetProvider() {
        return getterFunctions.targetProvider();
    },
};
// Offer Store Module mutations functions
function setLoading(state, payload) {
    state.loading = payload;
}
function setViewState(state, payload) {
    state.viewState = payload;
}
function clearViewState(state) {
    state.viewState = VIEW_STATE.VIEWING;
    state.targetOffer = undefined;
}
function setOffers(state, payload) {
    state.allOffers = payload;
}
// target offer
function setTargetOffer(state, payload) {
    state.targetOffer = payload;
}
function setTargetOfferInventoryType(state, payload) {
    state.targetOffer.inventory_type = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setTargetOfferInventorySubType(state, payload) {
    state.targetOffer.inventory_sub_type = payload;
    state.targetOffer = { ...state.targetOffer };
}
// providers
function setProviders(state, payload) {
    state.allProviders = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setProviderSourceMeta(state, payload) {
    // if payload null, set sourceMeta to null
    state.targetOffer.provider = payload;
    state.targetOffer = { ...state.targetOffer };
}
// offer type
function setOfferType(state, payload) {
    if (!state.targetOffer) {
        state.targetOffer = new OfferModel();
    }
    state.targetOffer.type = payload;
    state.targetOffer = { ...state.targetOffer };
}
// offer item
function setTargetOfferSubType(state, payload) {
    state.targetOffer.sub_type = payload;
    // set the source type for file to inventory, since that makes sense.
    state.targetOffer = { ...state.targetOffer };
}
function setMetadata(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata = { ...state.targetOffer.metadata, ...payload };
    state.targetOffer = { ...state.targetOffer };
}
function setDropTitle(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.title = payload;
    // .title = payload
    state.targetOffer = { ...state.targetOffer };
}
function setDropDescription(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.description = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setValue(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.value = payload;
    if (state.targetOffer.type === "DISCOUNT") {
        state.targetOffer.metadata.value_string = `${payload}%`;
    }
    state.targetOffer = { ...state.targetOffer };
}
function setQuantity(state, payload) {
    state.targetOffer.quantity = Number.parseInt(payload);
    state.targetOffer = { ...state.targetOffer };
}
function setDropDiscountReducedPrice(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.value_reduced = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropDiscountType(state, payload) {
    state.targetOffer.sub_type = payload;
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.value_type = "PERCENT";
    state.targetOffer = { ...state.targetOffer };
}
function setDropDiscountCode(state, payload) {
    state.targetOffer.reward = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropLegal(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.legal = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropRedemptionInstructions(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.redemption_instruct = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropRedemptionRequirements(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.redemption_req = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropExpirationDate(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata.expiration_date = payload
        ? new Date(payload)
        : null;
    state.targetOffer = { ...state.targetOffer };
}
// media
function setDropLogoUrl(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.companyMeta ??= {};
    state.targetOffer.companyMeta.logoUrl = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropCoverUrl(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.companyMeta ??= {};
    state.targetOffer.companyMeta.coverUrl = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropHeroUrl(state, payload) {
    // @logan - after much frustration and reactivity learning, optional chaining typescript features and versions
    // and figuring shit out and googling, it seems that
    // this is the best way to have REACTIVITY in your component files.
    // Vue apparently cannot detect property addition
    // or deletion, which is shown in this stack overflow answer... its not obvious but you need to replace your state
    // object with a new one, each time you add or delete a property. So any optional type will need this.
    // https://stackoverflow.com/questions/50770198/vuex-nested-objects
    // The idea is that you set the target prop you are manipulating, then you have to create a new object and set that.
    // this is done with the spread operator below.
    // we set the mainImageUrl, then we go ahead and return the whole new targetOffer
    state.targetOffer.metadata ??= {};
    state.targetOffer.heroUrl = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setDropImagesUrls(state, payload) {
    state.targetOffer.supporting_image_urls ??= [];
    state.targetOffer.supporting_image_urls.push(payload);
    state.targetOffer = { ...state.targetOffer };
}
function clearDropImagesUrls(state) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.supporting_image_urls = undefined;
    state.targetOffer = { ...state.targetOffer };
}
function setDropVideoUrl(state, payload) {
    state.targetOffer.video_url = payload;
    state.targetOffer = { ...state.targetOffer };
}
function setTargetProvider(state, payload) {
    state.targetProvider = payload;
    state.targetProvider = { ...state.targetProvider };
}
function clearTargetProvider(state) {
    state.targetProvider = undefined;
}
function clearTargetOffer(state) {
    state.targetOffer = undefined;
}
function setTargetOfferMetadata(state, payload) {
    state.targetOffer.metadata ??= {};
    state.targetOffer.metadata = { ...state.targetOffer.metadata, ...payload };
    state.targetOffer = { ...state.targetOffer };
}
// Offer Store module mutations export
export const mutations = {
    setLoading: OfferModule.commit(setLoading),
    setTargetOffer: OfferModule.commit(setTargetOffer),
    setTargetOfferInventoryType: OfferModule.commit(setTargetOfferInventoryType),
    setProviders: OfferModule.commit(setProviders),
    setProviderSourceMeta: OfferModule.commit(setProviderSourceMeta),
    setOffers: OfferModule.commit(setOffers),
    // view state
    setViewState: OfferModule.commit(setViewState),
    clearViewState: OfferModule.commit(clearViewState),
    // offer metadata
    setOfferType: OfferModule.commit(setOfferType),
    setTargetOfferSubType: OfferModule.commit(setTargetOfferSubType),
    setDropTitle: OfferModule.commit(setDropTitle),
    setDropDescription: OfferModule.commit(setDropDescription),
    setValue: OfferModule.commit(setValue),
    setDropDiscountReducedPrice: OfferModule.commit(setDropDiscountReducedPrice),
    setDropLogoUrl: OfferModule.commit(setDropLogoUrl),
    setDropCoverUrl: OfferModule.commit(setDropCoverUrl),
    setDropHeroUrl: OfferModule.commit(setDropHeroUrl),
    setDropImagesUrls: OfferModule.commit(setDropImagesUrls),
    clearDropImagesUrls: OfferModule.commit(clearDropImagesUrls),
    setDropVideoUrl: OfferModule.commit(setDropVideoUrl),
    setDropLegal: OfferModule.commit(setDropLegal),
    setDropRedemptionInstructions: OfferModule.commit(setDropRedemptionInstructions),
    setDropRedemptionRequirements: OfferModule.commit(setDropRedemptionRequirements),
    setDropExpirationDate: OfferModule.commit(setDropExpirationDate),
    setDropDiscountType: OfferModule.commit(setDropDiscountType),
    setDropDiscountCode: OfferModule.commit(setDropDiscountCode),
    setTargetOfferInventorySubType: OfferModule.commit(setTargetOfferInventorySubType),
    setTargetProvider: OfferModule.commit(setTargetProvider),
    clearTargetOffer: OfferModule.commit(clearTargetOffer),
    setTargetOfferMetadata: OfferModule.commit(setTargetOfferMetadata),
    setMetadata: OfferModule.commit(setMetadata),
    setQuantity: OfferModule.commit(setQuantity),
    clearTargetProvider: OfferModule.commit(clearTargetProvider),
};
const offerClient = new OfferClient();
// action functions
async function createOffer(context) {
    mutations.setLoading(true);
    try {
        await offerClient.create(context.state.targetOffer);
        notificationStore.actions.pushSuccess({
            title: "Offer Created.",
        });
        mutations.setTargetOffer(undefined);
        await getAllOffers();
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable To Create Offer.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function updateOffer(context) {
    mutations.setLoading(true);
    try {
        await offerClient.update(context.state.targetOffer);
        notificationStore.actions.pushSuccess({
            title: "Updated.",
        });
        mutations.setTargetOffer(undefined);
        await getAllOffers();
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable To Update.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function getAllOffers() {
    mutations.setLoading(true);
    try {
        const offers = await offerClient.readAll();
        mutations.setOffers(offers);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable To Retrieve Offers.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function getAllProviders() {
    mutations.setLoading(true);
    try {
        const gyftMerchants = await GyftProviderClient.readAllMerchants();
        mutations.setProviders(gyftMerchants);
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable To Retrieve Providers.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function uploadOfferImage(context, payload) {
    mutations.setLoading(true);
    try {
        const resp = await Client.uploadImage(payload.file);
        mutations.setLoading(false);
        return resp;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable to Upload Image.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function uploadOfferFile(context, payload) {
    mutations.setLoading(true);
    try {
        const resp = await Client.uploadImage(payload.file);
        mutations.setLoading(false);
        return resp;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable to Upload File.",
            error,
        });
    }
    mutations.setLoading(false);
}
async function getURLAsPDF(context, url) {
    mutations.setLoading(true);
    try {
        const response = await axios.get(url, {
            responseType: "blob",
        });
        console.log("Success", response);
        const blob = new Blob([response.data]);
        const objectUrl = URL.createObjectURL(blob);
        console.log("objectUrl", objectUrl);
        return objectUrl;
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable to fetch PDF.",
            error,
        });
    }
    mutations.setLoading(false);
}
// async function assignOffers(
//     context: ActionContext,
//     payload: { targetId: string; OfferIds: string[] }
// ) {
//     mutations.setLoading(true)
//     try {
//         await Client.assignOffers(payload.targetId, payload.OfferIds)
//         notificationStore.actions.pushSuccess({
//             title: 'Gift Cards Assigned.'
//         })
//     } catch (error) {
//         notificationStore.actions.pushError({
//             title: 'Unable To Assign Gift Cards.',
//             error
//         })
//     }
//     mutations.setLoading(false)
// }
// async function updateOffer(context: ActionContext, Offer: GiftDropOffer) {
//     mutations.setLoading(true)
//     try {
//         await Client.updateOffer(Offer)
//         notificationStore.actions.pushSuccess({
//             title: 'Gift Card Updated.'
//         })
//     } catch (error) {
//         notificationStore.actions.pushError({
//             title: 'Unable To Update Gift Card.',
//             error
//         })
//     }
//     mutations.setLoading(false)
// }
async function deleteOffer(context, offer) {
    mutations.setLoading(true);
    try {
        await offerClient.delete(offer.id);
        notificationStore.actions.pushSuccess({
            title: "Offer Removed.",
        });
        await getAllOffers();
    }
    catch (error) {
        notificationStore.actions.pushError({
            title: "Unable To Remove Offer.",
            error,
        });
    }
    mutations.setLoading(false);
}
// async function purchaseAndCreateGyftOffer(
//     context: ActionContext,
//     payload: { merchantId: string; shopCardId: number }
// ) {
//     mutations.setLoading(true)
//     try {
//         await Client.purchaseAndCreateGyftOffer(payload.merchantId, payload.shopCardId)
//         notificationStore.actions.pushSuccess({
//             title: 'Gift Card Created.'
//         })
//     } catch (error) {
//         notificationStore.actions.pushError({
//             title: 'Unable To Create Gift Card.',
//             error
//         })
//     }
//     mutations.setLoading(false)
// }
// async function updateGyftMerchantFilter(context: ActionContext, idsToBeFiltered: string[]) {
//     mutations.setLoading(true)
//     try {
//         await Client.updateGyftMerchantFilter(idsToBeFiltered)
//         notificationStore.actions.pushSuccess({
//             title: 'List Updated.'
//         })
//     } catch (error) {
//         notificationStore.actions.pushError({
//             title: 'Unable To Update List.',
//             error
//         })
//     }
//     mutations.setLoading(false)
// }
export const actions = {
    getAllOffers: OfferModule.dispatch(getAllOffers),
    createOffer: OfferModule.dispatch(createOffer),
    deleteOffer: OfferModule.dispatch(deleteOffer),
    updateOffer: OfferModule.dispatch(updateOffer),
    getAllProviders: OfferModule.dispatch(getAllProviders),
    uploadOfferImage: OfferModule.dispatch(uploadOfferImage),
    uploadOfferFile: OfferModule.dispatch(uploadOfferFile),
    getURLAsPDF: OfferModule.dispatch(getURLAsPDF),
    // assignOffers: OfferModule.dispatch(assignOffers),
    // updateOffer: OfferModule.dispatch(updateOffer),
    // deleteOffer: OfferModule.dispatch(deleteOffer),
    // purchaseAndCreateGyftOffer: OfferModule.dispatch(purchaseAndCreateGyftOffer),
    // updateGyftMerchantFilter: OfferModule.dispatch(updateGyftMerchantFilter)
};

/* eslint-disable */
import { USER_ROLES } from "@gd-mono/gd-common";
import { storeBuilder } from "../RootState";
import * as notificationStore from "./NotificationStore";
import { UserClientImpl } from "@/service/UserClientImpl";
// @alejandro: allow new and legacy user types for now
// import { User as User2 } from '@/store/types/auth'
export var USER_VIEW_STATE;
(function (USER_VIEW_STATE) {
    USER_VIEW_STATE[USER_VIEW_STATE["EDITING"] = 0] = "EDITING";
    USER_VIEW_STATE[USER_VIEW_STATE["VIEWING"] = 1] = "VIEWING";
    USER_VIEW_STATE[USER_VIEW_STATE["CREATING"] = 2] = "CREATING";
})(USER_VIEW_STATE || (USER_VIEW_STATE = {}));
/**
 * @description Base store state for handling user data
 * @author Alejandro Nieto
 * @date 4/25/2022
 * @export
 * @class UserState
 */
export class UserState {
    loading = false;
    viewState = USER_VIEW_STATE.VIEWING;
    user = null;
    allUsers = Array();
    allMerchants = Array();
    userDetails = null;
}
// use module builder
const userModule = storeBuilder.module("user", new UserState());
// getters
const getterFunctions = {
    allUsers: userModule.read((state) => state.allUsers, "allUsers"),
    getUser: userModule.read((state) => state.user, "getUser"),
    getUserDetails: userModule.read((state) => state.userDetails, "getUserDetails"),
    getLoading: userModule.read((state) => state.loading, "getLoading"),
    userHasCompany: userModule.read((state) => !!state.user.companyId, "userHasCompany"),
    allMerchants: userModule.read((state) => state.allMerchants, "allMerchants"),
    getPhone: userModule.read((state) => state.user.phone, "getPhone"),
    unapprovedMerchants: userModule.read((state) => state.allMerchants.filter((user) => {
        return user.role === "MERCHANT" && user.approved === false;
    }), "unnapprovedMerchants"),
    isCreating: userModule.read((state) => state.viewState === USER_VIEW_STATE.CREATING, "isCreating"),
    isEditing: userModule.read((state) => state.viewState === USER_VIEW_STATE.EDITING, "isEditing"),
    isViewing: userModule.read((state) => state.viewState === USER_VIEW_STATE.VIEWING, "isViewing"),
};
export const getters = {
    get allUsers() {
        return getterFunctions.allUsers();
    },
    get allMerchants() {
        return getterFunctions.allMerchants();
    },
    get getUser() {
        return getterFunctions.getUser();
    },
    get getUserDetails() {
        return getterFunctions.getUserDetails();
    },
    get getLoading() {
        return getterFunctions.getLoading();
    },
    get getPhone() {
        return getterFunctions.getPhone();
    },
    get unapprovedMerchants() {
        return getterFunctions.unapprovedMerchants();
    },
    get isCreating() {
        return getterFunctions.isCreating();
    },
    get isEditing() {
        return getterFunctions.isEditing();
    },
    get isViewing() {
        return getterFunctions.isViewing();
    },
};
/**
 * can include context: ActionContext as the first argument if you need root state
 *
 * @param {ActionContext} context
 */
const actionFunctions = {
    async getAllUsers(context) {
        mutations.setLoading(true);
        try {
            const users = await UserClientImpl.getInstance().readAll();
            mutations.setUsers(users);
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting users.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async getUser(context, userId) {
        mutations.setLoading(true);
        try {
            const user = await UserClientImpl.getInstance().read(userId);
            mutations.setUser(user);
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting user.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async getAllMerchants(context) {
        mutations.setLoading(true);
        try {
            const merchants = await UserClientImpl.getInstance().readAll();
            const filteredMerchants = merchants.filter((merchant) => merchant.role === USER_ROLES.MERCHANT);
            mutations.setMerchants(filteredMerchants);
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error getting merchants.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async approveMerchant(context, merchantId) {
        mutations.setLoading(true);
        try {
            await UserClientImpl.getInstance().approve(merchantId, true);
            notificationStore.actions.pushSuccess({
                title: "Merchant approved.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error approving merchant.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async blockUser(context, payload) {
        mutations.setLoading(true);
        try {
            await UserClientImpl.getInstance().block(payload.userId, payload.block);
            const users = await UserClientImpl.getInstance().readAll();
            mutations.setUsers(users);
            notificationStore.actions.pushSuccess({
                title: "User blocked.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error blocking user.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async deleteUser(context, uid) {
        mutations.setLoading(true);
        try {
            await UserClientImpl.getInstance().delete(uid);
            const users = await UserClientImpl.getInstance().readAll();
            mutations.setUsers(users);
            notificationStore.actions.pushSuccess({
                title: "User deleted.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error deleting user.",
                error,
            });
        }
        mutations.setLoading(false);
    },
    async updateUser(context) {
        mutations.setLoading(true);
        try {
            let phone = "";
            if (!context.state.user.phone.includes("+1")) {
                phone = `+1${context.state.user.phone}`;
            }
            else {
                phone = context.state.user.phone;
            }
            const updatedUser = await UserClientImpl.getInstance().update(context.state.user);
            mutations.setUser(updatedUser);
            notificationStore.actions.pushSuccess({
                title: "User updated.",
            });
        }
        catch (error) {
            notificationStore.actions.pushError({
                title: "Error updating user.",
                error,
            });
        }
        mutations.setLoading(false);
    },
};
export const actions = {
    // create: userModule.dispatch(actionFunctions.create),
    getAllUsers: userModule.dispatch(actionFunctions.getAllUsers),
    getUser: userModule.dispatch(actionFunctions.getUser),
    getAllMerchants: userModule.dispatch(actionFunctions.getAllMerchants),
    approveMerchant: userModule.dispatch(actionFunctions.approveMerchant),
    blockUser: userModule.dispatch(actionFunctions.blockUser),
    deleteUser: userModule.dispatch(actionFunctions.deleteUser),
    updateUser: userModule.dispatch(actionFunctions.updateUser),
};
// mutations
const mutationFunctions = {
    setUsers(state, users) {
        state.allUsers = users;
    },
    setMerchants(state, merchants) {
        state.allMerchants = merchants;
    },
    setUser(state, user) {
        state.user = user;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    updateEmail(state, payload) {
        state.user.email = payload;
    },
    updateTargetUser(state, payload) {
        state.user = payload;
    },
    updatePassword(state, payload) {
        // state.user.password = payload // Todo check where this is used and pass string instead of event
    },
    updateFirst(state, payload) {
        state.user.first = payload;
    },
    updateLast(state, payload) {
        state.user.last = payload;
    },
    updatePhone(state, payload) {
        state.user.phone = payload;
    },
    updatePin(state, payload) {
        state.user.pin = payload;
        state.user = { ...state.user };
    },
    setViewState(state, payload) {
        state.viewState = payload;
    },
};
export const mutations = {
    setUsers: userModule.commit(mutationFunctions.setUsers),
    setMerchants: userModule.commit(mutationFunctions.setMerchants),
    setUser: userModule.commit(mutationFunctions.setUser),
    setLoading: userModule.commit(mutationFunctions.setLoading),
    updateEmail: userModule.commit(mutationFunctions.updateEmail),
    updateTargetUser: userModule.commit(mutationFunctions.updateTargetUser),
    updatePassword: userModule.commit(mutationFunctions.updatePassword),
    updateFirst: userModule.commit(mutationFunctions.updateFirst),
    updateLast: userModule.commit(mutationFunctions.updateLast),
    updatePhone: userModule.commit(mutationFunctions.updatePhone),
    updatePin: userModule.commit(mutationFunctions.updatePin),
    setViewState: userModule.commit(mutationFunctions.setViewState),
};

import Vue from "vue";
import { Table, TableColumn, DropdownMenu, DropdownItem, Dropdown, } from "element-ui";
import moment from "moment";
import * as offerStore from "@/store/modules/OfferStore";
import SelectProviderCard from "@/offers/components/SelectProviderCard.vue";
import { SlideXRightTransition } from "vue2-transitions";
import { PROVIDERS_TYPE, Utils } from "@gd-mono/gd-common";
import { VIEW_STATE } from "@/store/RootState";
export default Vue.extend({
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        SelectProviderCard,
        SlideXRightTransition,
    },
    props: {
        targetProvider: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            searchTerms: "",
            companyFiltered: false,
            providersFiltered: [],
        };
    },
    async mounted() {
        // get all providers if not already loaded
        if (!this.allProviders) {
            await offerStore.actions.getAllProviders();
        }
        this.providersFiltered = this.allProviders;
        this.searchTerms = "";
        // if (this.providerMerchantId && this.viewing) {
        //     this.searchTerms = this.allProviders.find(
        //         (provider: GyftProviderData) =>
        //             provider.id === this.providerMerchantId,
        //     )?.name
        // } else {
        //     this.searchTerms = companyStore.getters.getAuthUserCompany?.name
        // }
        // this.companyFiltered = true
        this.filter();
    },
    watch: {
        searchTerms() {
            this.filter();
        },
    },
    computed: {
        offerStore() {
            return offerStore;
        },
        providerMerchantId() {
            return offerStore.getters.targetOffer?.provider?.merchant_id;
        },
        loading() {
            return offerStore.getters.loading;
        },
        allProviders() {
            return offerStore.getters.allProviders;
        },
        computedDate() {
            return (date) => moment(date).format("MM/DD/YY h:mm a");
        },
        viewing() {
            return offerStore.getters.viewState === VIEW_STATE.VIEWING;
        },
    },
    methods: {
        async handleCommand(commandObject) {
            if (!commandObject) {
                return;
            }
            switch (commandObject.action) {
                case "edit":
                    this.showEditOfferModal(commandObject.giftCard);
                    break;
                case "delete":
                    this.showRemoveModal(commandObject.giftCard);
                    break;
            }
        },
        // handles the row selection, which sets the current row and the target provider
        handleRowSelect(val) {
            if (!val) {
                return;
            }
            if (this.viewing) {
                return;
            }
            this.currentRow = val;
            this.searchTerms = val.name;
            // set provider in store
            offerStore.mutations.setTargetProvider(val);
            // set offer
            offerStore.mutations.setProviderSourceMeta({
                provider_type: PROVIDERS_TYPE.GYFT,
                merchant_name: val.name,
                merchant_id: val.id,
            });
            // set offer source type
            offerStore.mutations.setTargetOfferInventorySubType("GIFTCARD_PROVIDER");
            // set relative offer info
            offerStore.mutations.setDropTitle(`${val?.name} Gift Card`);
            offerStore.mutations.setDropHeroUrl(val?.icon_url);
            offerStore.mutations.setDropLogoUrl(val?.icon_url);
            offerStore.mutations.setDropLegal(Utils.stripHtmlTags(val?.legal_disclaimer_html));
            offerStore.mutations.setDropDescription(Utils.stripHtmlTags(val?.long_description));
        },
        filter() {
            let filtered = this.allProviders;
            // console.log('filtering')
            if (this.searchTerms) {
                filtered = filtered.filter((provider) => {
                    return (`${provider.name.toLowerCase()}`.search(`${this.searchTerms.toLowerCase()}`) !== -1);
                });
            }
            this.providersFiltered = filtered;
        },
    },
});

import Vue from 'vue';
import { SlideYUpTransition } from 'vue2-transitions';
import * as offerStore from '@/store/modules/OfferStore';
import OffersCardComponent from './OffersCardComponent.vue';
export default Vue.extend({
    components: { OffersCardComponent, SlideYUpTransition },
    data() {
        return {};
    },
    computed: {
        selectedSource() {
            return offerStore.getters.targetOffer?.inventory_type;
        },
        targetOffer() {
            return offerStore.getters.targetOffer;
        },
        barcodeInventory() {
            return offerStore.getters.targetOffer?.inventory_sub_type === 'BARCODE_IMAGE';
        },
        barcodeGenerate() {
            return offerStore.getters.targetOffer?.inventory_sub_type === 'BARCODE_GENERATE';
        },
        textVirtual() {
            return offerStore.getters.targetOffer?.inventory_sub_type === 'DISCOUNT_TEXT';
        },
        pdfInventory() {
            return offerStore.getters.targetOffer?.inventory_sub_type === 'PDF';
        }
    },
    methods: {
        offerSourceChange(sourceObj) {
            offerStore.mutations.setTargetOfferInventoryType(sourceObj.inventory_type);
            offerStore.mutations.setTargetOfferInventorySubType(sourceObj.inventory_sub_type);
        },
        next() {
            this.$emit('next');
        }
    }
});

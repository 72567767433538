var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("b-pagination", {
        attrs: {
          "first-number": "",
          "last-number": "",
          "per-page": _vm.perPage,
          size: _vm.size,
          value: _vm.value,
          align: _vm.align,
          "total-rows": _vm.total,
        },
        on: {
          change: function (val) {
            return _vm.$emit("change", val)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "prev-text",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: {
                      "aria-label": "Previous",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-angle-left",
                          attrs: {
                            "aria-hidden": "true",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "next-text",
            fn: function () {
              return [
                _c(
                  "a",
                  {
                    staticClass: "page-link",
                    attrs: {
                      "aria-label": "Next",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        attrs: {
                          "aria-hidden": "true",
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa fa-angle-right",
                          attrs: {
                            "aria-hidden": "true",
                          },
                        }),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }